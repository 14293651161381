import { useMutation, useQuery } from '@tanstack/react-query';
import { Alert, Button, Form, Input, Modal, message } from 'antd';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import axios from '../../config/axios';
import { Integration } from './ruth-connect-types';

type UpdateIntegrationModalProps = {
	open: boolean;
	close: () => void;
	data: Integration;
};

const UpdateIntegrationModal: FC<UpdateIntegrationModalProps> = (props) => {
	const [alertMessage, setAlertMessage] = useState<string | null>(null);
	const [form] = Form.useForm();

	const { refetch } = useQuery<Integration[]>({
		queryKey: ['connected-apps'],
	});

	// format label for form item
	const formatLabel = (label: string) => {
		return label
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	// handle form submission and prepare payload
	const handleSubmit = (values: unknown) => {
		const payload = {
			app_name: props.data.app_name,
			friendly_app_name: props.data.friendly_app_name,
			logo: props.data.logo,
			data: values,
			tools: props.data.tools,
		};

		// mutate integration
		mutate(payload);
	};

	// update integration mutation
	const { mutate, isPending } = useMutation({
		mutationFn: async (payload: unknown) => {
			await axios.patch(`/ruth-connect/integrations/${props.data.integration_id}`, payload, { withCredentials: true });
		},
		onSuccess: () => {
			refetch();
			message.success('Integration updated successfully.');

			props.close();
			setAlertMessage(null);
		},
		onError: (error: AxiosError<{ msg: string }>) => {
			if (error.response) {
				setAlertMessage(error.response.data.msg);
			}
		},
	});

	return (
		<Modal
			open={props.open}
			onCancel={props.close}
			footer={null}
			destroyOnClose
			title="Update Integration"
			centered
		>
			<Form
				form={form}
				layout="vertical"
				onFinish={handleSubmit}
			>
				{Object.keys(props.data.data).map((key) => (
					<Form.Item
						key={key}
						label={<div className="text-sm font-medium">{formatLabel(key)}</div>}
						name={key}
					>
						<Input.TextArea
							autoSize
							size="large"
							required
							placeholder='Enter value'
						/>
					</Form.Item>
				))}

				<Form.Item className="mb-0">
					<Button
						type="primary"
						htmlType="submit"
						block
						loading={isPending}
						disabled={isPending}
						className="font-medium"
					>
						Update
					</Button>
				</Form.Item>
			</Form>

			{alertMessage && (
				<div className="pt-5">
					<Alert
						message={alertMessage}
						type={alertMessage.includes('successfully') ? 'success' : 'error'}
						showIcon
					/>
				</div>
			)}
		</Modal>
	);
};

export default UpdateIntegrationModal;
