import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Alert, Button, Card, Divider, Modal, Select } from 'antd';
import { AxiosError } from 'axios';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsChevronDown } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import Loader from '../components/Loader';
import OutboundCallModal from '../components/outbound-call-modal/outbound-call-modal';
import PurchasePhoneDrawer from '../components/purchase-phone-drawer';
import axios from '../config/axios';
import { cn } from '../config/cn';
import AppLayout from '../layouts/app.layout';
import { AIAssistant } from '../types/ai-assistant.types';
import { UserPhoneNumber } from '../types/phone-numbers.types';

const PhoneNumbers = () => {
	const [page] = useState(1);
	const limit = 30;
	const [searchParams, setSearchParams] = useSearchParams();
	const [makeOutboundCallModalOpen, setMakeOutboundCallModalOpen] = useState(false);
	const [purchasePhoneDrawerOpen, setPurchasePhoneDrawerOpen] = useState(false);
	const [noAgentError, setNoAgentError] = useState(false);
	const [numbersModalOpen, setNumbersModalOpen] = useState(false);

	// get all phone numbers
	const { data, isLoading, refetch } = useQuery<{
		count: number;
		results: UserPhoneNumber[];
	}>({
		queryKey: ['phone-numbers', page, limit],
		queryFn: async () => {
			const { data } = await axios.get('/phone-numbers', { withCredentials: true, params: { page, limit } });

			return data;
		},
	});

	// set first phone number as default if not set
	useEffect(() => {
		if (!searchParams.get('phone_number') && data && data.results.length > 0) {
			setSearchParams({
				phone_number: String(data.results[0].phone_number_id),
			});
		}
	}, [data, searchParams, setSearchParams]);

	// get phone number from url
	const current_phone_number = searchParams.get('phone_number');

	// get phone number data
	const { data: phone_number, isLoading: phoneNumberLoading } = useQuery<UserPhoneNumber>({
		queryKey: ['phone-number', current_phone_number],
		queryFn: async () => {
			const { data } = await axios.get(`/phone-numbers/${current_phone_number}`, { withCredentials: true });

			return data.results;
		},
		gcTime: 0,
		staleTime: Infinity,
		enabled: !!current_phone_number,
	});

	// get all ai assistants
	const { data: ai_assistants } = useQuery<{
		count: number;
		results: AIAssistant[];
	}>({
		queryKey: ['ai-assistants'],
		queryFn: async () => {
			const { data } = await axios.get('/ai-assistants', { withCredentials: true, params: { limit: 50 } });

			return data;
		},
	});

	// delete phone number mutation
	const { mutate: deletePhoneNumber } = useMutation({
		mutationFn: async (phone_number_id: string) => {
			await axios.delete(`/phone-numbers/${phone_number_id}`, { withCredentials: true });
		},
		onSuccess: () => {
			toast.success('Phone number deleted successfully.');
			refetch();
			searchParams.delete('phone_number');
		},
	});

	// close error message after 3 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setNoAgentError(false);
		}, 3000);

		return () => clearTimeout(timer);
	}, [noAgentError]);

	return (
		<Fragment>
			<AppLayout
				title="Phone Numbers"
				subtitle="Manage your phone numbers here."
			>
				{isLoading && (
					<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
						<div className="flex h-full items-center justify-center">
							<Loader />
						</div>
					</Card>
				)}

				{data && data.results.length === 0 && (
					<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
						<div className="flex h-full items-center justify-center">
							<div className="flex flex-col gap-2 text-center">
								<div className="text-base font-medium text-black-3">You don't have any Phone Number.</div>
								<Button
									type="primary"
									onClick={() => setPurchasePhoneDrawerOpen(true)}
								>
									Buy One
								</Button>
							</div>
						</div>
					</Card>
				)}

				{data && data.results.length > 0 && (
					<div className="flex flex-col gap-6 md:flex-row">
						{/* mobile version */}
						<div className="md:hidden">
							{data.results.map((item, i) => {
								if (item.phone_number_id === searchParams.get('phone_number')) {
									return (
										<Card
											key={i}
											styles={{ body: { padding: 10 } }}
											role="button"
											className="shadow-sm"
											onClick={() => {
												// open modal
												setNumbersModalOpen(true);
											}}
										>
											<div className="flex items-center justify-between gap-2">
												<div className="flex items-center gap-2.5">
													<img
														src="/images/call-logs/call-logs.svg"
														alt="call-logs"
													/>

													<div className="flex flex-col">
														<span className="text-base font-medium text-black-7 dark:text-white">
															{item.friendly_name}
														</span>
														<span className="text-sm font-medium text-black-3">
															{item.additional_data.locality}, {item.additional_data.region}{' '}
															{item.additional_data.iso_country}
														</span>
													</div>
												</div>

												<div>
													<BsChevronDown size={20} />
												</div>
											</div>
										</Card>
									);
								}
								return null;
							})}

							{/* phone numbers modal */}
							<Modal
								title="Purchased Numbers"
								open={numbersModalOpen}
								onCancel={() => setNumbersModalOpen(false)}
								footer={null}
								centered
							>
								<div className="pt-3" />

								<div className="flex flex-col gap-3.5">
									{data.results.map((item, i) => (
										<div
											key={i}
											className={cn(
												'rounded-lg border bg-white px-2 py-2.5 shadow-sm transition-all duration-300 dark:bg-[#161422]',
												{
													'border-primary': searchParams.get('phone_number') === String(item.phone_number_id),
													'dark:border-dark-border': searchParams.get('phone_number') !== String(item.phone_number_id),
												},
											)}
											onClick={() => {
												setSearchParams({
													phone_number: String(item.phone_number_id),
												});
												setNumbersModalOpen(false);
											}}
											role="button"
										>
											<div className="flex items-center gap-2">
												<img
													src="/images/phone-numbers/purchased-numbers.svg"
													alt="purchased-numbers"
												/>

												<div className="flex flex-col">
													<span className="text-base font-medium text-black-7 dark:text-white">{item.friendly_name}</span>
													<span className="text-sm font-medium text-black-3">
														{item.additional_data.locality}, {item.additional_data.region}{' '}
														{item.additional_data.iso_country}
													</span>
												</div>
											</div>
										</div>
									))}
								</div>
							</Modal>
						</div>

						{/* desktop version */}
						<div className="sticky top-28 hidden h-[calc(100dvh-8rem)] w-[21rem] overflow-hidden md:block">
							<Card
								className="scrollbar-hidden h-full bg-input-bg dark:bg-dark-sidebar"
								styles={{ body: { padding: 0, height: '100%' } }}
							>
								<div className="flex items-center rounded-t-lg border-b bg-white px-3 py-5 dark:border-b-[#2d2b38] dark:bg-[#161422]">
									<div className="text-xl font-semibold leading-none text-black-7 dark:text-white">Purchased Numbers</div>
								</div>

								<div className="flex flex-col gap-3.5 p-3">
									{data.results.map((item, i) => (
										<div
											key={i}
											className={cn(
												'rounded-lg border bg-white px-2 py-2.5 shadow-sm transition-all duration-300 dark:bg-[#161422]',
												{
													'border-primary': searchParams.get('phone_number') === String(item.phone_number_id),
													'dark:border-[#14121f]': searchParams.get('phone_number') !== String(item.phone_number_id),
												},
											)}
											onClick={() => {
												setSearchParams({
													phone_number: String(item.phone_number_id),
												});
											}}
											role="button"
										>
											<div className="flex items-center gap-2">
												<img
													src="/images/phone-numbers/purchased-numbers.svg"
													alt="purchased-numbers"
												/>

												<div className="flex flex-col">
													<span className="text-base font-medium text-black-7 dark:text-white">{item.friendly_name}</span>
													<span className="text-sm font-medium text-black-3">
														{item.additional_data.locality}, {item.additional_data.region}{' '}
														{item.additional_data.iso_country}
													</span>
												</div>
											</div>
										</div>
									))}
								</div>
							</Card>
						</div>
						<div className="flex-1 pt-2">
							<div className="h-full">
								{current_phone_number ? (
									<Fragment>
										{(!searchParams.get('phone_number') || phoneNumberLoading) && (
											<div className="flex h-[calc(100dvh-20rem)] items-center justify-center md:h-full">
												<Loader />
											</div>
										)}

										{searchParams.get('phone_number') && phone_number && (
											<section>
												<div className="flex flex-wrap items-center justify-between gap-4">
													<section>
														<h2 className="text-xl font-bold dark:text-white">Phone Numbers</h2>

														<div className="text-sm font-medium text-black-3">
															You can purchase a phone number to make and receive calls.
														</div>
													</section>

													<Button
														type="primary"
														size="large"
														icon={<PlusOutlined />}
														onClick={() => setPurchasePhoneDrawerOpen(true)}
														className="font-medium"
													>
														Purchase Phone Number
													</Button>
												</div>

												<Divider />

												<section>
													{noAgentError && (
														<Alert
															message="Please select an agent."
															type="error"
															showIcon
														/>
													)}

													<h2
														className={cn('pb-2 text-base font-medium dark:text-white', {
															'pt-2': noAgentError,
														})}
													>
														Select an agent
													</h2>

													<Select
														onChange={async (value) => {
															try {
																await axios.patch(
																	`/phone-numbers/${phone_number.phone_number_id}`,
																	{ ai_assistant: value },
																	{ withCredentials: true },
																);
																toast.success('Agent connected successfully.');
																setNoAgentError(false);
															} catch (error) {
																if (error instanceof AxiosError && error.response) {
																	toast.error(error.response.data.msg);
																} else {
																	toast.error('Failed to connect agent.');
																}
															}
														}}
														options={
															ai_assistants
																? ai_assistants.results.map((item) => ({
																		label: item.name,
																		value: item.assistant_id,
																	}))
																: []
														}
														size="large"
														className="w-full"
														placeholder="Select an agent"
														defaultValue={phone_number.ai_assistant && phone_number.ai_assistant.assistant_id}
														virtual={false}
													/>
												</section>

												<div className="pt-6" />

												<Card styles={{ body: { padding: 16 } }}>
													<div className="flex items-center justify-between">
														<h3 className="text-xl font-semibold">Outbound Call</h3>

														<img
															src="/images/ai-assistant/delete-icon.svg"
															alt="delete-icon"
															onClick={() => {
																deletePhoneNumber(phone_number.phone_number_id);
															}}
															role="button"
															className="size-8"
														/>
													</div>

													<div className="pt-5" />

													<div className="flex flex-wrap items-center justify-between gap-4">
														<section>
															<div className="text-2xl font-semibold text-black-7 dark:text-white">
																{phone_number.friendly_name}
															</div>

															<div className="pt-1" />

															<div className="text-base font-medium text-black-3">
																Phone Number: {phone_number.phone_number}
															</div>
														</section>

														<section>
															<Button
																type="primary"
																icon={
																	<img
																		src="/images/phone-numbers/make-outbound-call.svg"
																		alt="make-outbound-call"
																	/>
																}
																size={'large'}
																onClick={() => {
																	if (!phone_number.ai_assistant) {
																		setNoAgentError(true);
																		return;
																	}
																	setMakeOutboundCallModalOpen(true);
																}}
																className="font-medium"
															>
																Make an outbound call
															</Button>
														</section>
													</div>
												</Card>
												<OutboundCallModal
													open={makeOutboundCallModalOpen}
													close={() => setMakeOutboundCallModalOpen(false)}
													from_number={phone_number.phone_number}
													variables={phone_number.ai_assistant ? phone_number.ai_assistant.variables : []}
												/>
											</section>
										)}
									</Fragment>
								) : (
									<Card className="flex h-full items-center justify-center">
										<div className="flex flex-col gap-2 text-center">
											<div className="text-base font-medium text-gray-600">Select a Phone Number to view details.</div>
										</div>
									</Card>
								)}
							</div>
						</div>
					</div>
				)}
			</AppLayout>

			{/* purchase phone drawer */}
			<PurchasePhoneDrawer
				open={purchasePhoneDrawerOpen}
				close={() => setPurchasePhoneDrawerOpen(false)}
				refetch={refetch}
			/>
		</Fragment>
	);
};

export default PhoneNumbers;
