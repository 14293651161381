import { FC, PropsWithChildren, createContext, useState } from 'react';

interface ContextProps {
	isCollapsed: boolean;
	setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarContext = createContext<ContextProps>({
	isCollapsed: false,
	setIsCollapsed: () => false,
});

export const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isCollapsed, setIsCollapsed] = useState(false);

	return (
		<SidebarContext.Provider
			value={{
				isCollapsed,
				setIsCollapsed,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};
