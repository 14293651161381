import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Button, Table, Tag } from 'antd';
import { FC, Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import dayjs from '../../config/dayjs';
import { Campaign } from '../../views/campaigns';
import { CampaignTask } from './campaign-drawer';

type Props = {
	open: boolean;
	campaign: Campaign;
};

const CampaignTasks: FC<Props> = (props) => {
	const [campaignTaskPage, setCampaignTaskPage] = useState<number>(1);

	// get campaign tasks
	const {
		data: campaignTasks,
		isLoading: campaignTasksLoading,
		refetch: campaignTasksRefresh,
		isFetching: campaignTasksFetching,
	} = useQuery<{
		count: number;
		results: CampaignTask[];
	}>({
		queryKey: ['campaign-tasks', props.campaign.campaign_id, campaignTaskPage],
		queryFn: async () => {
			const { data } = await axios.get(`/campaigns/${props.campaign.campaign_id}/tasks`, {
				withCredentials: true,
				params: {
					page: campaignTaskPage,
					limit: 10,
				},
			});

			return data;
		},
		enabled: props.open,
		placeholderData: keepPreviousData,
		refetchInterval: 90 * 1000,
	});

	return (
		<Fragment>
			<div className="flex items-center justify-between gap-2">
				<div className="text-lg font-semibold text-black-7 dark:text-white">Leads</div>

				<div className="flex items-center gap-2">
					<span className="relative flex size-2">
						<span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-600 opacity-75"></span>
						<span className="relative inline-flex size-2 rounded-full bg-green-600"></span>
					</span>
					<h2 className="text-sm font-medium text-gray-500">Refresh in 1.5 Minute</h2>
				</div>
			</div>

			<div className="pt-3" />

			<Table
				dataSource={campaignTasks && campaignTasks.results}
				bordered
				size="small"
				columns={[
					{
						title: 'To Number',
						dataIndex: 'to_number',
						key: 'to_number',
					},
					{
						title: 'Completed',
						dataIndex: 'is_completed',
						key: 'is_completed',
						render: (value) => (value ? <Tag color="#108ee9">Complete</Tag> : <Tag color="#f50">Incomplete</Tag>),
					},
					{
						title: 'Completed At',
						dataIndex: 'completed_at',
						key: 'completed_at',
						render: (value) => (value ? dayjs(value).format('DD MMM, YYYY HH:mm A') : 'Not Completed.'),
					},
					{
						title: 'Variables',
						dataIndex: 'variables',
						key: 'variables',
						render: (variables) => (
							<div>
								{variables === null && 'No variables.'}

								{variables && variables.length === 0 && 'No variables.'}

								{variables &&
									variables.length > 0 &&
									variables.map(
										(
											variable: {
												var_name: string;
												var_value: string;
											},
											index: number,
										) => (
											<div key={index}>
												<span className="font-medium">{variable.var_name}: </span> {variable.var_value}
											</div>
										),
									)}
							</div>
						),
					},
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<Button
								icon={
									<img
										src="/images/ai-assistant/delete-icon.svg"
										alt="delete-icon"
									/>
								}
								onClick={async () => {
									await axios.delete(`/campaigns/${props.campaign.campaign_id}/tasks/${record.to_number}`, {
										withCredentials: true,
									});
									toast.success('Task deleted successfully');
									campaignTasksRefresh();
								}}
							/>
						),
					},
				]}
				loading={campaignTasksLoading || campaignTasksFetching}
				rowKey={(record) => record.to_number}
				pagination={{
					hideOnSinglePage: true,
					showSizeChanger: false,
					showQuickJumper: false,
					showTotal: (total) => `Total ${total} tasks`,
					onChange: (page) => setCampaignTaskPage(page),
					current: campaignTaskPage,
					total: campaignTasks && campaignTasks.count,
				}}
			/>
		</Fragment>
	);
};

export default CampaignTasks;
