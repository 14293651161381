import Icon from '@ant-design/icons';
import { Button, Drawer, Input, Select, Table, Tooltip } from 'antd';
import { FC, Fragment, useEffect, useState } from 'react';
import { BsCartCheck } from 'react-icons/bs';
import { GoInfo } from 'react-icons/go';
import { GrSearch } from 'react-icons/gr';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoRefreshCircleOutline } from 'react-icons/io5';
import { PiPaintBrushHouseholdLight } from 'react-icons/pi';
import { RiCloseCircleFill } from 'react-icons/ri';
import { useWindowSize } from 'react-use';
import axios from '../config/axios';
import { cn } from '../config/cn';
import { us_states } from '../data/data';
import { useThemeStore } from '../store/theme';
import { PhoneNumber } from '../types/phone-numbers.types';
import BuyPhoneModal from './BuyPhoneModal';
import Loader from './Loader';

type Props = {
	open: boolean;
	close: () => void;
	refetch: () => void;
};

const PurchasePhoneDrawer: FC<Props> = ({ open, close, refetch }) => {
	const { is_dark_mode } = useThemeStore();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState<PhoneNumber[] | undefined>(undefined);
	const [refresh, setRefresh] = useState<number>(0);
	const [refreshing, setRefreshing] = useState(false);
	const [state, setState] = useState<string | null>();
	const [searchCriteria, setSearchCriteria] = useState<string | null>('area_code');
	const [characters, setCharacters] = useState<string | null>(null);
	const [filter, setFilter] = useState({});
	const [toBuyPhoneModalOpen, setToBuyPhoneModalOpen] = useState(false);
	const [toBuyPhone, setToBuyPhone] = useState<PhoneNumber | null>(null);

	const { width } = useWindowSize();

	// get available phone numbers
	useEffect(() => {
		(async () => {
			try {
				setRefreshing(true);
				const { data } = await axios.get('/phone-numbers/available-phone-numbers', {
					withCredentials: true,
					params: filter,
				});
				setData(data.results);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
				setRefreshing(false);
			}
		})();
	}, [refresh, filter]);

	return (
		<Fragment>
			<Drawer
				title="Purchase Phone Number"
				onClose={close}
				open={open}
				width={width > 768 ? '70%' : '100%'} // responsive drawer width
			>
				<h2 className="text-2xl font-semibold text-gray-700 dark:text-white">Buy a Number</h2>

				<div className="w-full">
					<div className="pb-3 pt-2">
						<label
							htmlFor="country"
							className="block pb-1 text-sm font-medium text-gray-700 dark:text-white"
						>
							Select State
						</label>
						<Select
							options={us_states.map((state) => ({
								value: state.code,
								label: state.name,
							}))}
							className="w-full"
							placeholder="Select a state"
							size="large"
							virtual={false}
							onChange={(value) => setState(value)}
							value={state}
						/>
					</div>

					<div className="flex flex-wrap items-end gap-3">
						<div className="w-full md:w-[20%]">
							<div className="block text-sm font-medium text-gray-700">
								<div className="flex items-center gap-1 pb-1">
									<label
										htmlFor="search_criteria"
										className="block text-sm font-medium text-gray-700 dark:text-white"
									>
										Search Criteria
									</label>
									<Tooltip title="Search by area code or characters you want in your phone number.">
										<GoInfo
											role="button"
											size={18}
											color={is_dark_mode ? '#fff' : '#000'}
										/>
									</Tooltip>
								</div>
							</div>
							<Select
								options={[
									{ value: 'area_code', label: 'Area Code' },
									{ value: 'search', label: 'Characters' },
								]}
								placeholder="Select a search criteria"
								className="w-full"
								size="large"
								onChange={(value) => setSearchCriteria(value)}
								value={searchCriteria as string}
							/>
						</div>

						<div className="flex-1">
							<div className="flex gap-3">
								<Input
									placeholder="Enter area code or characters"
									size="large"
									value={characters as string}
									onChange={(e) => setCharacters(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-3 pt-3 md:flex-row">
						<Button
							type="primary"
							size="large"
							icon={<Icon component={GrSearch} />}
							onClick={() => {
								setFilter({
									region: state ? state : null,
									...(searchCriteria === 'area_code' && { area_code: characters }),
									...(searchCriteria === 'search' && { search: characters }),
								});
							}}
							className="w-full px-6 md:w-auto"
							loading={refreshing}
						>
							Search
						</Button>
						<Button
							type="dashed"
							size="large"
							icon={<Icon component={PiPaintBrushHouseholdLight} />}
							onClick={() => {
								setState(null);
								setSearchCriteria('area_code');
								setCharacters(null);
								setFilter({});
							}}
							className="w-full font-semibold md:w-auto"
						>
							Clear Filters
						</Button>
					</div>
				</div>

				{loading && (
					<div className="flex h-[50dvh] items-center justify-center">
						<Loader />
					</div>
				)}

				{data && (
					<>
						<div
							className="-mt-4 flex justify-end pb-2 pr-2"
							role="button"
							onClick={() => {
								setRefresh((prev) => prev + 1);
							}}
						>
							<IoRefreshCircleOutline
								size={30}
								className={cn({
									'animate-spin': refreshing,
								})}
								color="#10b981"
								title="Refresh"
							/>
						</div>
						<Table
							pagination={false}
							dataSource={data}
							size="middle"
							className="[&>div>div>div>div>div>table]:rounded-md [&>div>div>div>div>div>table]:border [&>div>div>div>div>div>table]:border-b-0"
							columns={[
								{
									title: 'Number',
									dataIndex: 'friendly_name',
									key: 'friendly_name',
									render: (text: string, record) => (
										<div className="flex flex-col gap-px">
											<h2 className="text-base font-bold text-gray-800">{text}</h2>
											<div className="font-semibold text-gray-600">
												{record.locality && `${record.locality},`} {record.region} {record.iso_country}
											</div>
										</div>
									),
									width: 350,
								},
								{
									title: 'Postal Code',
									dataIndex: 'postal_code',
									key: 'postal_code',
									render: (text: string) => <span>{text || 'N/A'}</span>,
								},
								{
									title: 'Capabilities',
									children: [
										{
											title: 'Voice',
											dataIndex: 'voice',
											key: 'voice',
											render: (_, record) => (
												<div>
													{record.capabilities.voice ? (
														<div className="text-green-500">
															<IoIosCheckmarkCircle size={20} />
														</div>
													) : (
														<div className="text-red-500">
															<RiCloseCircleFill size={20} />
														</div>
													)}
												</div>
											),
										},
										{
											title: 'SMS',
											dataIndex: 'SMS',
											key: 'SMS',
											render: (_, record) => (
												<div>
													{record.capabilities.SMS ? (
														<div className="text-green-500">
															<IoIosCheckmarkCircle size={20} />
														</div>
													) : (
														<div className="text-red-500">
															<RiCloseCircleFill size={20} />
														</div>
													)}
												</div>
											),
										},
									],
								},
								{
									title: 'Monthly Fee',
									dataIndex: 'monthly_fee',
									key: 'monthly_fee',
									width: 250,
									render: (text, record) => (
										<div className="flex items-center justify-between">
											<div className="text-base font-bold">${text}</div>

											<Button
												type="primary"
												icon={<Icon component={BsCartCheck} />}
												onClick={() => {
													setToBuyPhone(record);
													setToBuyPhoneModalOpen(true);
												}}
											>
												Buy Now
											</Button>
										</div>
									),
								},
							]}
						/>
					</>
				)}
			</Drawer>

			{toBuyPhone && (
				<BuyPhoneModal
					open={toBuyPhoneModalOpen}
					close={() => setToBuyPhoneModalOpen(false)}
					phone_number={toBuyPhone}
					closeDrawer={close}
					refetch={refetch}
				/>
			)}
		</Fragment>
	);
};

export default PurchasePhoneDrawer;
