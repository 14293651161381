import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, Modal, message } from 'antd';
import { AxiosError } from 'axios';
import { FC, Fragment, useContext, useState } from 'react';
import axios from '../../config/axios';
import { AuthContext } from '../../context/AuthContext';
import ConfirmPaymentIntent from './confirm-payment-intent';
import { Card } from './payment-methods';

type AddFundModalProps = {
	open: boolean;
	close: () => void;
	card: Card;
};

const AddFundModal: FC<AddFundModalProps> = (props) => {
	const [confirmPaymentIntentOpen, setConfirmPaymentIntentOpen] = useState<boolean>(false);
	const [confirmPaymentIntentSecret, setConfirmPaymentIntentSecret] = useState<string>('');
	const { setUser } = useContext(AuthContext);

	// add fund mutation
	const { mutate, isPending } = useMutation({
		mutationFn: async (v: { amount: string }) => {
			const { data } = await axios.post(
				'/billing/add-funds',
				{
					amount: v.amount,
					payment_method: props.card.id,
				},
				{ withCredentials: true },
			);

			return data;
		},
		onSuccess: async () => {
			message.success('Funds added successfully');
			props.close();

			// get updated user balance
			const { data } = await axios.get('/users/authenticate', { withCredentials: true });
			setUser(data.results);
		},
		onError: (error: AxiosError<{ results: { success: boolean; client_secret: string } }>) => {
			if (error.response) {
				if (error.response.data.results.success === false) {
					// show confirm payment intent modal
					setConfirmPaymentIntentOpen(true);
					setConfirmPaymentIntentSecret(error.response.data.results.client_secret);
				}
			}
		},
	});

	return (
		<Fragment>
			<Modal
				title="Add Fund"
				open={props.open}
				onCancel={props.close}
				centered
				footer={null}
				destroyOnClose
			>
				<Form
					layout="vertical"
					onFinish={(v) => {
						mutate(v);
					}}
				>
					<Form.Item
						label={<div className="font-medium">Amount</div>}
						name="amount"
					>
						<Input
							placeholder="Amount"
							type="number"
							name="amount"
							required
							inputMode="numeric"
							step={0.01}
							size="large"
						/>
					</Form.Item>

					<Form.Item className="mb-0">
						<Button
							type="primary"
							htmlType="submit"
							block
							size="large"
							loading={isPending}
							disabled={isPending}
						>
							Add Fund
						</Button>
					</Form.Item>
				</Form>
			</Modal>

			{/* payment intent modal */}
			<Modal
				open={confirmPaymentIntentOpen}
				footer={null}
				centered
				closable={false}
			>
				<ConfirmPaymentIntent
					secret={confirmPaymentIntentSecret}
					closeModal={() => {
						setConfirmPaymentIntentOpen(false);
						props.close();
					}}
				/>
			</Modal>
		</Fragment>
	);
};

export default AddFundModal;
