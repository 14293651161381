import { ReactNode } from 'react';
import CalendlyConnectUI from './calendly-connect-ui';
import Upcoming from './upcoming-ui';
import apple from '/images/apple_calendar.png';
import calendly from '/images/calendly.svg';
import clickup from '/images/clickup.webp';
import googleDrive from '/images/google-drive.png';
import google from '/images/google_calendar.png';
import hubspot from '/images/hubspot.webp';
import jira from '/images/jira.png';
import outlook from '/images/outlook.png';
import teams from '/images/teams.png';
import yahoo from '/images/yahoo.png';
import zapier from '/images/zapier.svg';

export type Filter = {
	label: string;
	value: string;
};

export type App = {
	uuid: string;
	image: string;
	vendor: string;
	app_name: string;
	title: string;
	content: string;
	categories: string[];
	connect_UI: ReactNode;
};

export const filters: Filter[] = [
	{
		label: 'All',
		value: 'all',
	},
	{
		label: 'Calendars',
		value: 'calendars',
	},
	{
		label: 'Automation',
		value: 'automation',
	},
	{
		label: 'CRM',
		value: 'crm',
	},
	{
		label: 'Analytics',
		value: 'analytics',
	},
	{
		label: 'Messaging',
		value: 'messaging',
	},
	{
		label: 'Conferencing',
		value: 'conferencing',
	},
];

export const mostPopular: App[] = [
	{
		image: calendly,
		vendor: 'Calendly',
		title: 'Calendly',
		content: 'Connect your Calendly with Ruth® Connect for a unified scheduling experience across both platforms.',
		categories: ['analytics', 'automation', 'crm'],
		app_name: 'calendly',
		connect_UI: <CalendlyConnectUI />,
		uuid: crypto.randomUUID(),
	},
	{
		image: apple,
		vendor: 'Apple',
		title: 'iCloud Calendar',
		content: 'Sync your iCloud calendar with Ruth® Connect to seamlessly manage and update your events, ensuring.',
		categories: ['calendars', 'automation', 'crm'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: google,
		vendor: 'Google',
		title: 'Google Calendar',
		content: 'Integrate your Google Calendar with Ruth® Connect for a streamlined management of your events across both platforms.',
		categories: ['calendars', 'conferencing', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: teams,
		vendor: 'Microsoft',
		title: 'Teams',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'automation', 'analytics'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: outlook,
		vendor: 'Microsoft',
		title: 'Outlook Calendar',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'crm', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
];

export const apps: App[] = [
	{
		image: apple,
		vendor: 'Apple',
		title: 'iCloud Calendar',
		content: 'Sync your iCloud calendar with Ruth® Connect to seamlessly manage and update your events, ensuring.',
		categories: ['calendars', 'automation', 'crm'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: google,
		vendor: 'Google',
		title: 'Google Calendar',
		content: 'Integrate your Google Calendar with Ruth® Connect for a streamlined management of your events across both platforms.',
		categories: ['calendars', 'conferencing', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: calendly,
		vendor: 'Calendly',
		title: 'Calendly',
		content: 'Connect your Calendly with Ruth® Connect for a unified scheduling experience across both platforms.',
		categories: ['analytics', 'automation', 'crm'],
		app_name: 'calendly',
		connect_UI: <CalendlyConnectUI />,
		uuid: crypto.randomUUID(),
	},
	{
		image: outlook,
		vendor: 'Microsoft',
		title: 'Outlook Calendar',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'crm', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: teams,
		vendor: 'Microsoft',
		title: 'Teams',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'automation', 'analytics'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: googleDrive,
		vendor: 'Google',
		title: 'Google Drive',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'conferencing', 'crm'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},

	{
		image: yahoo,
		vendor: 'Yahoo',
		title: 'Yahoo Calendar',
		content: 'Sync your iCloud calendar with Ruth® Connect to seamlessly manage and update your events, ensuring.',
		categories: ['calendars', 'messaging', 'automation'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: jira,
		vendor: 'Jira',
		title: 'Jira Board',
		content: 'Integrate your Google Calendar with Ruth® Connect for a streamlined management of your events across both platforms.',
		categories: ['calendars', 'analytics', 'crm'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: zapier,
		vendor: 'Zapier',
		title: 'Zapier Automation',
		content: 'Connect your Calendly with Ruth® Connect for a unified scheduling experience across both platforms.',
		categories: ['calendars', 'automation', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: clickup,
		vendor: 'ClickUp',
		title: 'ClickUp Task Management',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'conferencing', 'analytics'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
	{
		image: hubspot,
		vendor: 'HubSpot',
		title: 'HubSpot Calendar',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'crm', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: crypto.randomUUID(),
	},
];
