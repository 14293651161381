import { Button, Input, Modal } from 'antd';
import { FC, Fragment } from 'react';
import { FaRegFaceSmile } from 'react-icons/fa6';
import { GrRevert } from 'react-icons/gr';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoChevronForwardSharp } from 'react-icons/io5';
import { TbMessageCircleShare } from 'react-icons/tb';
import { colors } from '../../theme/colors';
import FormLabel from './form-label';
import { Tab, tabs } from './tabs-data';

type BehaviorFormProps = {
	beginMessage: string;
	setBeginMessage: (value: string) => void;
	whoSpeaksFirst: string;
	setWhoSpeaksFirst: (value: string) => void;
	setActiveTab: (value: Tab) => void;
};

type WhoSpeaksFirst = {
	title: string;
	description: string;
	value: string;
};

const whoSpeakFirstList: WhoSpeaksFirst[] = [
	{
		title: 'Assistant Speaks First',
		description: 'Assistant will start the conversation.',
		value: 'assistant',
	},
	{
		title: 'User Speaks First',
		description: 'User will start the conversation.',
		value: 'user',
	},
];

const BehaviorForm: FC<BehaviorFormProps> = (props) => {
	const handleNextButtonClick = () => {
		// handle next button click
		if (!props.beginMessage) {
			return Modal.error({
				title: 'Error',
				content: 'Please enter a begin message.',
				centered: true,
			});
		}

		// set the next tab
		props.setActiveTab(tabs[2]);
	};

	return (
		<Fragment>
			<div>
				<FormLabel
					label="WHO SPEAKS FIRST"
					icon={<FaRegFaceSmile size={20} />}
				/>

				<div className="pt-3" />

				<div className="grid grid-cols-1 gap-4">
					{whoSpeakFirstList.map((speaker, index) => (
						<div
							key={index}
							className="flex items-center justify-between rounded-xl border px-3 py-2 dark:border-[#322f3d]"
							role="button"
							onClick={() => props.setWhoSpeaksFirst(speaker.value)}
						>
							<div>
								<div className="font-semibold">{speaker.title}</div>
								<div className="text-sm text-gray-500">{speaker.description}</div>
							</div>

							{speaker.value === props.whoSpeaksFirst ? (
								<div>
									<IoIosCheckmarkCircle
										color={colors.primary}
										size={20}
									/>
								</div>
							) : null}
						</div>
					))}
				</div>
			</div>

			{props.whoSpeaksFirst === 'assistant' && (
				<Fragment>
					<div className="pt-6" />

					<div>
						<FormLabel
							label="BEGIN MESSAGE"
							icon={<TbMessageCircleShare size={20} />}
						/>

						<div className="pt-1" />

						<p className="text-sm font-semibold text-gray-500">Your assistant will say this message to start every conversation.</p>

						<div className="pt-3" />

						<Input.TextArea
							size="large"
							value={props.beginMessage}
							onChange={(e) => props.setBeginMessage(e.target.value)}
							placeholder="Hello! How can I help you today?"
							autoSize={{ minRows: 3, maxRows: 5 }}
						/>
					</div>
				</Fragment>
			)}

			<div className="pt-6" />

			<div className="grid grid-cols-2 gap-5">
				<Button
					size="large"
					type="dashed"
					block
					icon={<GrRevert />}
					onClick={() => {
						props.setActiveTab(tabs[0]);
					}}
				>
					Back
				</Button>

				<Button
					size="large"
					type="primary"
					block
					icon={<IoChevronForwardSharp />}
					iconPosition="end"
					onClick={handleNextButtonClick}
				>
					Next
				</Button>
			</div>
		</Fragment>
	);
};

export default BehaviorForm;
