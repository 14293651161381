import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Dropdown } from 'antd';
import { FC, useState } from 'react';
import { SiCalendly } from 'react-icons/si';
import { Link } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import axios from '../../config/axios';
import { Tool } from '../../store/tools';
import { LlmStates } from '../../types/ai-assistant.types';
import { bookAppointmentParameters, checkCalendarAvailabilityParameters } from '../ai-assistants/parameters';
import { Integration } from '../ruth-connect/ruth-connect-types';
import BookOnCalendar from './calendly-book-on-calendar';
import CheckCalendarAvailability from './calendly-check-calendar-availability';

type LLMStatesToolsProps = {
	llm_states: LlmStates;
};

const LLMStatesTools: FC<LLMStatesToolsProps> = (props) => {
	const [activeTab, setActiveTab] = useState<string[] | null>(() =>
		props.llm_states.tools ? props.llm_states.tools.map((tool) => tool.functionId) : null,
	);

	const { setNodes } = useReactFlow();

	const [tabs, setTabs] = useState<{ label: string; icon: JSX.Element; functionId: string }[]>([]);

	const { data: connectedApps } = useQuery<Integration[]>({
		queryKey: ['connected-apps'],
		queryFn: async () => {
			const { data } = await axios.get('/ruth-connect/integrations', { withCredentials: true });

			for (const item of data.results) {
				if (item.app_name === 'calendly') {
					setTabs((prev) => {
						// Check if the functionIds already exist in the tabs array
						const hasBookOnCalendar = prev.some((tab) => tab.functionId === 'calendly_create_calendar_event');
						const hasCheckCalendarAvailability = prev.some((tab) => tab.functionId === 'calendly_get_user_busy_times');

						// Only add the functionIds if they don't already exist
						if (!hasBookOnCalendar) {
							prev.push({
								label: 'Book on Calendar (Calendly)',
								icon: <SiCalendly size={20} />,
								functionId: 'calendly_create_calendar_event',
							});
						}
						if (!hasCheckCalendarAvailability) {
							prev.push({
								label: 'Check Calendar Availability (Calendly)',
								icon: <SiCalendly size={20} />,
								functionId: 'calendly_get_user_busy_times',
							});
						}

						return [...prev];
					});
				}
			}

			return data.results;
		},
	});

	const handleInitialNodes = (functionId: string) => {
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					const tools = node.data.item.tools || [];

					const toolIndex = tools.findIndex((tool: Tool) => tool.functionId === functionId);

					if (toolIndex !== -1) {
						// Update existing tool
						tools[toolIndex] = {
							...tools[toolIndex],
							function: {
								...tools[toolIndex].function,
								name: tools[toolIndex].function.name,
								description: tools[toolIndex].function.description,
							},
						};
					} else {
						// Add new tool
						const newTool =
							functionId === 'calendly_create_calendar_event'
								? {
										type: 'function',
										functionId: 'calendly_create_calendar_event',
										function: {
											name: 'book_appointment',
											description:
												'Book an appointment in the calendar. This function will book an appointment in the calendar for the given date and time.',
										},
										parameters: bookAppointmentParameters,
									}
								: {
										type: 'function',
										functionId: 'calendly_get_user_busy_times',
										function: {
											name: 'check_calendar_availability',
											description:
												'Get get busy time from the calendar. This function will return the busy time from the calendar for the given date range. Date range should not exceed 7 days.',
										},
										parameters: checkCalendarAvailabilityParameters,
									};

						tools.push(newTool);
					}

					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: tools,
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});
	};

	// handle delete tool
	const handleDeleteTool = (functionId: string) => {
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					const tools = node.data.item.tools || [];

					const newTools = tools.filter((tool: Tool) => tool.functionId !== functionId);

					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: newTools,
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});

		// remove from active tab
		setActiveTab((prev) => prev && prev.filter((tab) => tab !== functionId));
	};

	return (
		<div className="min-h-80">
			<div className="flex items-center justify-between">
				<div className="space-y-1">
					<h2 className="text-lg font-semibold text-black-7 dark:text-white">Tools (Optional)</h2>
					<p className="w-4/5 text-sm font-medium text-black-3">
						Enable your agent with capabilities such as calendar bookings, call termination, or your own custom functions.
					</p>
				</div>

				<Dropdown
					menu={{
						items: tabs.map(({ label, icon, functionId }, index) => ({
							key: index,
							icon: icon,
							label: label,
							onClick: () => {
								setActiveTab((prev) => (prev && prev.concat(functionId)) ?? [functionId]);
								handleInitialNodes(functionId);
							},
						})),
					}}
					trigger={['click']}
					disabled={tabs.length === 0}
				>
					<Button
						size="large"
						type="primary"
						icon={<PlusOutlined />}
					>
						Add Tool
					</Button>
				</Dropdown>
			</div>

			<div className="py-2" />

			{connectedApps && connectedApps.length === 0 && props.llm_states.tools.length === 0 && (
				<div className="flex flex-wrap items-center justify-center space-x-2 pt-20 text-center text-gray-500 sm:flex-nowrap">
					<span>You have not connected any app yet. Connect an app from the</span>{' '}
					<Link
						to="/ruth-connect"
						className="text-primary"
						target="_blank"
					>
						Ruth Connect
					</Link>{' '}
					<span>page to add tools.</span>
				</div>
			)}

			<div className="space-y-4">
				{activeTab && activeTab.includes('calendly_create_calendar_event') && (
					<BookOnCalendar
						llm_states={props.llm_states}
						handleDeleteTool={handleDeleteTool}
					/>
				)}

				{activeTab && activeTab.includes('calendly_get_user_busy_times') && (
					<CheckCalendarAvailability
						llm_states={props.llm_states}
						handleDeleteTool={handleDeleteTool}
					/>
				)}
			</div>
		</div>
	);
};

export default LLMStatesTools;
