import RuthConnectApps from '../components/ruth-connect/ruth-connect-apps';
import RuthConnectMostPopular from '../components/ruth-connect/ruth-connect-most-popular';
import RuthConnectSlider from '../components/ruth-connect/ruth-connect-slider';
import AppLayout from '../layouts/app.layout';

const RuthConnect = () => {
	return (
		<AppLayout
			title="Ruth® Connect"
			subtitle="Seamless Multichannel Integration for your favorite platforms."
		>
			<RuthConnectSlider />

			<div className="pt-6" />

			<RuthConnectMostPopular />

			<div className="pt-8" />

			<RuthConnectApps />

			<div className="pb-6" />
		</AppLayout>
	);
};

export default RuthConnect;
