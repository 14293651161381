import { useMutation, useQuery } from '@tanstack/react-query';
import { Alert, Button, Form, Input, message } from 'antd';
import { AxiosError } from 'axios';
import { Fragment, useState } from 'react';
import axios from '../../config/axios';
import { useRuthConnectStore } from '../../store/ruth-connect';
import { Integration } from './ruth-connect-types';

const CalendlyConnectUI = () => {
	const [alertMessage, setAlertMessage] = useState<string | null>(null);
	const { setOpenModalId } = useRuthConnectStore();

	const { refetch } = useQuery<Integration[]>({
		queryKey: ['connected-apps'],
	});

	// connect calendly mutation
	const { mutate, isPending } = useMutation({
		mutationFn: async (payload: unknown) => {
			await axios.post('/ruth-connect/integrations', payload, { withCredentials: true });
		},
		onSuccess: () => {
			message.success('Calendly connected successfully.');

			setOpenModalId(null);
			setAlertMessage(null);
			refetch();
		},
		onError: (error: AxiosError<{ msg: string }>) => {
			if (error.response) {
				setAlertMessage(error.response.data.msg);
			}
		},
	});

	return (
		<Fragment>
			<Form
				layout="vertical"
				onFinish={(values) => {
					const payload = {
						app_name: 'calendly',
						friendly_app_name: 'calendly',
						logo: 'https://puretalk-dashboard-rh5d8.ondigitalocean.app/images/calendly.svg',
						data: {
							api_key: values.api_key,
						},
						tools: ['calendly_check_calendar_availabilty', 'calendly_book_meeting'],
					};

					// mutate integration
					mutate(payload);
				}}
			>
				<Form.Item
					label="Calendly API Key"
					name="api_key"
				>
					<Input.TextArea
						autoSize
						size="large"
						required
					/>
				</Form.Item>

				<Form.Item className="mb-0">
					<Button
						type="primary"
						htmlType="submit"
						block
						loading={isPending}
						disabled={isPending}
						className="font-medium"
					>
						Connect
					</Button>
				</Form.Item>
			</Form>

			{alertMessage && (
				<div className="pt-5">
					<Alert
						message={alertMessage}
						type={alertMessage.includes('successfully') ? 'success' : 'error'}
						showIcon
					/>
				</div>
			)}
		</Fragment>
	);
};

export default CalendlyConnectUI;
