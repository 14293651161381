import { useQuery } from '@tanstack/react-query';
import { Fragment } from 'react';
import RuthConnectCard from './ruth-connect-card';
import { App, mostPopular } from './ruth-connect-data';
import { Integration } from './ruth-connect-types';

const RuthConnectMostPopular = () => {
	const { data: connectedApps } = useQuery<Integration[]>({
		queryKey: ['connected-apps'],
	});

	return (
		<Fragment>
			<h2 className={'text-lg font-medium text-gray-700 dark:text-white'}>Most Popular</h2>

			<div className="pt-3" />

			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
				{mostPopular.map((item: App, index: number) => (
					<RuthConnectCard
						item={item}
						key={index}
						connectedApps={connectedApps as Integration[]}
					/>
				))}
			</div>
		</Fragment>
	);
};

export default RuthConnectMostPopular;
