import { InfoCircleOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Avatar, Button, Card, Input, Modal, Spin, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, Fragment, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa6';
import { FiPlus } from 'react-icons/fi';
import { GoCheckCircleFill } from 'react-icons/go';
import { IoDuplicateOutline } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useClickAway } from 'react-use';
import Loader from '../components/Loader';
import AIAssistantChatBot from '../components/ai-assistants/ai-assistant-chatbot.tsx';
import AssistantGeneralSettings from '../components/ai-assistants/assistant-general-settings.tsx';
import MakeCallModal from '../components/ai-assistants/assistant-make-a-call-modal.tsx';
import AssistantTools from '../components/ai-assistants/assistant-tools.tsx';
import AssistantVoices from '../components/ai-assistants/assistant-voices.tsx';
import UpdateAssistant from '../components/ai-assistants/update-assistant.tsx';
import CreateAIAssistantModal from '../components/create-ai-assistant-widget/create-ai-assistant-modal.tsx';
import TestLLMDrawer from '../components/test-llm-drawer/test-llm-drawer';
import axios from '../config/axios';
import { cn } from '../config/cn';
import { SidebarContext } from '../context/SidebarContext.tsx';
import AppLayout from '../layouts/app.layout';
import { useThemeStore } from '../store/theme.ts';
import { AIAssistant } from '../types/ai-assistant.types';
import { handleAPIError } from '../utils/helpers.ts';

type Tab = {
	label: string;
	value: string;
	activeIcon: string;
	inactiveIcon: string;
};

const tabs: Tab[] = [
	{
		label: 'Model',
		activeIcon: '/images/ai-assistant/model-active-icon.svg',
		inactiveIcon: '/images/ai-assistant/model-inactive-icon.svg',
		value: 'model',
	},
	{
		label: 'Voices',
		activeIcon: '/images/ai-assistant/voices-active-icon.svg',
		inactiveIcon: '/images/ai-assistant/voices-inactive-icon.svg',
		value: 'voices',
	},
	{
		label: 'Tools',
		activeIcon: '/images/ai-assistant/tools-active-icon.svg',
		inactiveIcon: '/images/ai-assistant/tools-inactive-icon.svg',
		value: 'tools',
	},
	{
		label: 'General',
		activeIcon: '/images/ai-assistant/advanced-active-icon.svg',
		inactiveIcon: '/images/ai-assistant/advanced-inactive-icon.svg',
		value: 'general',
	},
	{
		label: 'Chatbot',
		activeIcon: '/images/ai-assistant/chatbot-active-icon.svg',
		inactiveIcon: '/images/ai-assistant/chatbot-inactive-icon.svg',
		value: 'chatbot',
	},
];

const AIAssistants = () => {
	const { is_dark_mode } = useThemeStore();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [page] = useState(1);
	const limit = 30;
	const [createAssistantModalOpen, setCreateAssistantModalOpen] = useState(false);
	const [showUpdateName, setShowUpdateName] = useState(false);
	const [updatedName, setUpdatedName] = useState<string | null>(null);
	const [selectedTab, setSelectedTab] = useState('model');
	const [testLLMDrawerOpen, setTestLLMDrawerOpen] = useState<boolean>(false);
	const [showCallModal, setShowCallModal] = useState<boolean>(false);
	const [copied, setCopied] = useState(false);
	const [updateAvatarLoading, setUpdateAvatarLoading] = useState<boolean>(false);
	const [assistantsModalOpen, setAssistantsModalOpen] = useState(false);
	const { isCollapsed } = useContext(SidebarContext);
	const [modal, contextHolder] = Modal.useModal();

	const name_ref = useRef(null);
	useClickAway(name_ref, () => {
		setShowUpdateName(false);
		updateAssistant({ name: updatedName as string });
	});

	// get all ai assistants
	const { data, isLoading, refetch } = useQuery<{
		count: number;
		results: AIAssistant[];
	}>({
		queryKey: ['ai-assistants', page, limit],
		queryFn: async () => {
			const { data } = await axios.get('/ai-assistants', { withCredentials: true, params: { page, limit } });

			return data;
		},
	});

	// set first assistant id to url if not set
	useEffect(() => {
		if (!searchParams.get('assistant') && data && data.results.length > 0) {
			setSearchParams({
				assistant: String(data.results[0].assistant_id),
			});
		}
	}, [data, searchParams, setSearchParams]);

	// get assistant id from url
	const assistantId = searchParams.get('assistant');

	// get assistant data
	const {
		data: assistant,
		isLoading: assistantDataLoading,
		refetch: refetchAssistant,
	} = useQuery<AIAssistant>({
		queryKey: ['ai-assistant', assistantId],
		queryFn: async () => {
			const { data } = await axios.get(`/ai-assistants/${assistantId}`, { withCredentials: true });

			return data.results;
		},
		gcTime: 0,
		staleTime: Infinity,
		enabled: !!assistantId,
	});

	// update assistant mutation
	const { mutate: updateAssistant } = useMutation({
		mutationFn: async (payload: Record<string, unknown>) => {
			const { data } = await axios.patch(`/ai-assistants/${assistantId}`, payload, { withCredentials: true });

			return data;
		},
		onSuccess: () => {
			refetch();
			refetchAssistant();
			setShowUpdateName(false);
		},
	});

	// delete assistant mutation
	const deleteAssistant = async () => {
		try {
			await axios.delete(`/ai-assistants/${assistantId}`, { withCredentials: true });
			const updatedData = await refetch();
			if ('data' in updatedData && updatedData.data) {
				// create a new URLSearchParams instance with the current search parameters
				const newSearchParams = new URLSearchParams(searchParams.toString());
				// if there are any remaining assistants, set the assistant parameter to the ID of the first one
				if (updatedData.data.results.length > 0) {
					newSearchParams.set('assistant', String(updatedData.data.results[0].assistant_id));
				} else {
					// otherwise, remove the assistant parameter
					newSearchParams.delete('assistant');
				}
				// replace the current search parameters with the new ones
				setSearchParams(newSearchParams);
				navigate('/ai-assistants');
			}
		} catch (error) {
			handleAPIError(error as AxiosError);
		}
	};

	// duplicate a new assistant mutation
	const { mutate: duplicateAssistant, isPending: duplicateAssistantPending } = useMutation({
		mutationFn: async (payload: Record<string, unknown>) => {
			const { data } = await axios.post('/ai-assistants', payload, { withCredentials: true });

			return {
				assistant: data.results,
				llm_states: payload.llm_states,
			};
		},
		onSuccess: async ({ assistant, llm_states }) => {
			// update llm states
			await axios.post(`/ai-assistants/llm-states/${assistant.assistant_id}`, { llm_states }, { withCredentials: true });

			// refetch
			refetch();
		},
	});

	// reset copied state after 2 seconds
	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 2000);

			return () => clearTimeout(timeout);
		}
	}, [copied]);

	// handle upload avatar
	const handleUploadUpdateAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
		try {
			setUpdateAvatarLoading(true);
			const file = e.target.files && e.target.files[0];
			const formData = new FormData();
			formData.append('image', file as File);

			// upload image
			const { data } = await axios.post('/commons/upload-image', formData, { withCredentials: true });

			// update dp
			await axios.patch(`/ai-assistants/${assistantId}`, { avatar: data.results.secure_url }, { withCredentials: true });

			toast.success('Profile picture updated successfully.');
			refetchAssistant();
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('Failed to upload profile picture.');
			}
		} finally {
			setUpdateAvatarLoading(false);
		}
	};

	return (
		<AppLayout
			title="AI Assistants"
			subtitle="Interact with our AI-powered assistant for real-time help."
		>
			{contextHolder}

			{isLoading && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full items-center justify-center">
						<Loader />
					</div>
				</Card>
			)}

			{data && data.results.length === 0 && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full items-center justify-center">
						<div className="flex flex-col gap-2 text-center">
							<div className="text-base font-medium text-gray-600 dark:text-white">You don't have any AI Assistant.</div>
							<Button
								type="primary"
								onClick={() => setCreateAssistantModalOpen(true)}
							>
								Create One
							</Button>
						</div>
					</div>
				</Card>
			)}

			{data && data.results.length > 0 && (
				<div className="flex flex-col gap-6 min-[895px]:flex-row">
					{/* mobile version */}
					<div className="min-[895px]:hidden">
						{data.results.map((item, i) => {
							if (item.assistant_id === searchParams.get('assistant')) {
								return (
									<Card
										key={i}
										styles={{ body: { padding: 10 } }}
										role="button"
										className="shadow-sm"
										onClick={() => {
											// open modal
											setAssistantsModalOpen(true);
										}}
									>
										<div className="flex items-center justify-between gap-2">
											<div className="flex items-center gap-3">
												<img
													src="/images/ai-assistant/assistant-stars.svg"
													alt="assistant-stars"
												/>
												<div className="text-base font-semibold">{item.name}</div>
											</div>

											<div>
												<BsChevronDown size={20} />
											</div>
										</div>
									</Card>
								);
							}
							return null;
						})}

						{/* assistants modal */}
						<Modal
							title="Your AI Assistant"
							open={assistantsModalOpen}
							onCancel={() => setAssistantsModalOpen(false)}
							footer={
								<Button
									onClick={() => {
										setCreateAssistantModalOpen(true);
										setAssistantsModalOpen(false);
									}}
									block
									icon={<FiPlus size={18} />}
									className="h-9 text-[15px] font-semibold dark:bg-[#1b1827] dark:shadow-none"
								>
									Create
								</Button>
							}
							centered
						>
							<div className="pt-3" />

							<div className="flex flex-col gap-3.5">
								{data.results.map((item, i) => (
									<div
										key={i}
										className={cn('rounded-lg border bg-white px-2 py-2.5 transition-all duration-300 dark:bg-[#161422]', {
											'border-primary': searchParams.get('assistant') === String(item.assistant_id),
											'dark:border-dark-border': searchParams.get('assistant') !== String(item.assistant_id),
										})}
										onClick={() => {
											setSearchParams({
												assistant: String(item.assistant_id),
											});
											setSelectedTab('model');
											setAssistantsModalOpen(false);
										}}
										role="button"
									>
										<div className="flex items-center gap-3">
											<img
												src="/images/ai-assistant/assistant-stars.svg"
												alt="assistant-stars"
											/>
											<div className="text-base font-semibold">{item.name}</div>
										</div>
									</div>
								))}
							</div>
						</Modal>
					</div>

					{/* desktop version */}
					<div className="sticky top-28 hidden h-[calc(100dvh-8rem)] w-[21rem] overflow-hidden min-[895px]:block">
						<Card
							className="h-full overflow-y-auto bg-input-bg dark:bg-dark-sidebar"
							styles={{ body: { padding: 0, height: '100%' } }}
						>
							<div className="flex items-center justify-between gap-2 rounded-t-lg border-b bg-white p-3 dark:border-b-[#2d2b38] dark:bg-[#161422]">
								<div className="text-xl font-semibold leading-none text-black-7 dark:text-white">Your AI Assistant</div>

								<Button
									onClick={() => setCreateAssistantModalOpen(true)}
									icon={<FiPlus size={18} />}
									className="h-9 text-[15px] font-semibold dark:bg-[#1b1827] dark:shadow-none"
								>
									Create
								</Button>
							</div>

							<div className="flex flex-col gap-3.5 p-3">
								{data.results.map((item, i) => (
									<div
										key={i}
										className={cn('rounded-lg border bg-white px-2 py-2.5 transition-all duration-300 dark:bg-[#161422]', {
											'border-primary': searchParams.get('assistant') === String(item.assistant_id),
											'dark:border-[#14121f]': searchParams.get('assistant') !== String(item.assistant_id),
										})}
										onClick={() => {
											setSearchParams({
												assistant: String(item.assistant_id),
											});
											setSelectedTab('model');
										}}
										role="button"
									>
										<div className="flex items-center gap-3">
											<img
												src="/images/ai-assistant/assistant-stars.svg"
												alt="assistant-stars"
											/>
											<div className="text-base font-semibold">{item.name}</div>
										</div>
									</div>
								))}
							</div>
						</Card>
					</div>
					<div className="flex-1 pb-6 pt-2">
						<div className="h-full">
							{assistantId ? (
								<Fragment>
									{assistantDataLoading && (
										<div className="flex h-[calc(100dvh-20rem)] items-center justify-center md:h-full">
											<Loader />
										</div>
									)}

									{assistant && (
										<div>
											<div className="flex flex-wrap items-start justify-between gap-5">
												<div className="flex items-center gap-3">
													<div className="relative pt-1">
														<Spin
															spinning={updateAvatarLoading}
															size="small"
														>
															<Avatar
																src={assistant.avatar}
																size={44}
															/>
														</Spin>

														<label
															className="absolute -right-0 bottom-0 flex items-center justify-center rounded-full border bg-white p-[3px] dark:border-[#353050] dark:bg-[#161422]"
															role="button"
															htmlFor="edit-avatar"
															title="Edit AI Assistant Avatar"
														>
															<img
																src={
																	is_dark_mode
																		? '/images/ai-assistant/edit-icon-dark.svg'
																		: '/images/ai-assistant/edit-icon.svg'
																}
																alt="edit-icon"
																className="size-3"
															/>
														</label>

														<input
															type="file"
															id={'edit-avatar'}
															className={'hidden'}
															onChange={handleUploadUpdateAvatar}
														/>
													</div>

													<div>
														{showUpdateName ? (
															<div
																style={{ width: 200 }}
																ref={name_ref}
															>
																<Input
																	value={updatedName as string}
																	onChange={(e) => {
																		setUpdatedName(e.target.value);
																	}}
																	onPressEnter={() => {
																		updateAssistant({
																			name: updatedName as string,
																		});
																	}}
																	autoFocus
																	variant="filled"
																	suffix={
																		<Tooltip title="Press enter or outside to save.">
																			<InfoCircleOutlined />
																		</Tooltip>
																	}
																/>
															</div>
														) : (
															<div className="flex items-center gap-2">
																<h3 className="text-xl font-bold dark:text-white">{assistant.name}</h3>
																<div
																	className="text-gray-600"
																	role="button"
																	onClick={() => {
																		setShowUpdateName(true);
																		setUpdatedName(assistant.name);
																	}}
																>
																	<img
																		src={
																			is_dark_mode
																				? '/images/ai-assistant/edit-icon-dark.svg'
																				: '/images/ai-assistant/edit-icon.svg'
																		}
																		alt="edit-icon"
																	/>
																</div>
															</div>
														)}

														<div
															className="flex flex-wrap items-center gap-1.5 pt-1"
															role="button"
															onClick={() => {
																navigator.clipboard.writeText(assistant.assistant_id);
																setCopied(true);
																toast.success('Assistant ID copied to clipboard.');
															}}
														>
															<p className={'text-sm font-medium text-gray-500'}>
																Assistant ID: {assistant.assistant_id}
															</p>

															<div className="text-sm text-gray-500">
																{copied ? <GoCheckCircleFill color="green" /> : <FaRegCopy />}
															</div>
														</div>
													</div>
												</div>
												<div className="flex flex-wrap items-center gap-3">
													<Button
														type="dashed"
														icon={<Icon component={AiOutlineDelete} />}
														danger
														title="Delete AI Assistant"
														onClick={() => {
															modal.confirm({
																title: 'Delete AI Assistant',
																content: 'Are you sure you want to delete this AI Assistant?',
																onOk: async () => {
																	await deleteAssistant();
																},
																centered: true,
																okButtonProps: { danger: true },
																okText: 'Delete',
															});
														}}
														size="large"
													/>

													<Button
														icon={<Icon component={IoDuplicateOutline} />}
														title="Duplicate AI Assistant"
														loading={duplicateAssistantPending}
														disabled={duplicateAssistantPending}
														onClick={() => {
															duplicateAssistant({
																...assistant,
																name: `${assistant.name} (Copy)`,
																voice: assistant.voice.id,
																llm_states: assistant.llm_states.map((state) => {
																	return {
																		...state,
																		state_id: undefined,
																	};
																}),
															});
														}}
														size="large"
													/>
													<Button
														icon={
															<img
																src={
																	is_dark_mode
																		? '/images/ai-assistant/test-assistant-icon-dark.svg'
																		: '/images/ai-assistant/test-assistant-icon.svg'
																}
																alt="test-assistant-icon"
															/>
														}
														size="large"
														onClick={() => {
															setTestLLMDrawerOpen(true);
														}}
														className="text-base font-semibold text-black-7 dark:text-white"
													>
														Test Assistant
													</Button>

													<Button
														type="primary"
														icon={
															<img
																src="/images/ai-assistant/call-icon.svg"
																alt="call-icon"
															/>
														}
														size="large"
														className="text-base font-semibold"
														onClick={() => {
															setShowCallModal(true);
														}}
													>
														Make A Call
													</Button>
												</div>
											</div>

											<div className="pt-5" />

											<div
												className={cn('scrollbar-hidden w-[calc(100dvw-2.5rem)] overflow-x-scroll md:w-full', {
													'min-[1100px]:w-[calc(100dvw-44rem)] min-[1152px]:w-full': !isCollapsed,
												})}
											>
												<div className="inline-flex items-center gap-3 border-b-2 bg-white min-[523px]:flex dark:border-b-[#2d2b38] dark:bg-dark-bg">
													{tabs.map((tab, index) => (
														<div
															key={index}
															className={cn(
																'relative flex items-center justify-between gap-4 rounded-md rounded-b-none px-3 py-2',
																{
																	'transition-all duration-500': selectedTab === tab.value,
																},
															)}
															onClick={() => {
																setSelectedTab(tab.value);
															}}
															role="button"
														>
															<div className="flex min-w-max items-center gap-2">
																<div>
																	{selectedTab === tab.value ? (
																		<img
																			src={tab.activeIcon}
																			alt={tab.label}
																			className="size-6"
																		/>
																	) : (
																		<img
																			src={tab.inactiveIcon}
																			alt={tab.label}
																			className="size-6"
																		/>
																	)}
																</div>
																<div
																	className={cn('text-base font-semibold text-black-3', {
																		'text-link-active': selectedTab === tab.value,
																		'dark:text-[#8a8990]': selectedTab === tab.value,
																	})}
																>
																	{tab.label}
																</div>
															</div>

															{tab.value === selectedTab && (
																<motion.div
																	className="absolute -bottom-[2px] left-0 right-0 h-0.5 bg-primary"
																	layoutId="underline"
																/>
															)}
														</div>
													))}
												</div>
											</div>

											<div className="pt-6" />

											<AnimatePresence mode="wait">
												<motion.div
													key={selectedTab}
													initial={{ y: 10, opacity: 0 }}
													animate={{ y: 0, opacity: 1 }}
													exit={{ y: -10, opacity: 0 }}
													transition={{ duration: 0.2 }}
												>
													{selectedTab === 'model' && (
														<UpdateAssistant
															assistant={assistant}
															assistantId={assistantId}
															refetch={refetch}
															refetchAssistant={refetchAssistant}
														/>
													)}

													{selectedTab === 'voices' && (
														<AssistantVoices
															assistant={assistant}
															refetch={refetch}
															refetchAssistant={refetchAssistant}
														/>
													)}

													{selectedTab === 'tools' && <AssistantTools assistant={assistant} />}

													{selectedTab === 'general' && (
														<AssistantGeneralSettings
															assistant={assistant}
															refetch={refetch}
															refetchAssistant={refetchAssistant}
														/>
													)}

													{selectedTab === 'chatbot' && (
														<AIAssistantChatBot
															assistant={assistant}
															refetch={refetch}
															refetchAssistant={refetchAssistant}
														/>
													)}
												</motion.div>
											</AnimatePresence>
										</div>
									)}
								</Fragment>
							) : (
								<Card className="flex h-full items-center justify-center">
									<div className="flex flex-col gap-2 text-center">
										<div className="text-base font-medium text-gray-600">Select an AI Assistant to view details.</div>
									</div>
								</Card>
							)}
						</div>
					</div>
				</div>
			)}

			{/* create ai assistant modal */}
			<CreateAIAssistantModal
				open={createAssistantModalOpen}
				close={() => setCreateAssistantModalOpen(false)}
				refetch={refetch}
			/>

			{/*	Test LLM Drawer*/}
			{assistant && (
				<TestLLMDrawer
					open={testLLMDrawerOpen}
					close={() => setTestLLMDrawerOpen(false)}
					assistant={assistant}
				/>
			)}

			{/* make a call modal */}

			{assistant && (
				<MakeCallModal
					open={showCallModal}
					close={() => setShowCallModal(false)}
					assistant={assistant}
				/>
			)}
		</AppLayout>
	);
};

export default AIAssistants;
