import { Button, Card, Input, Space } from 'antd';
import { FC } from 'react';
import { useReactFlow } from 'reactflow';
import { Tool } from '../../store/tools';
import { LlmStates } from '../../types/ai-assistant.types';

type BookOnCalendarProps = {
	llm_states: LlmStates;
	handleDeleteTool: (functionId: string) => void;
};

const BookOnCalendar: FC<BookOnCalendarProps> = (props) => {
	const { setNodes } = useReactFlow();

	// first check if the assistant already has the tool by functionId
	const tools = props.llm_states.tools || [];
	const tool = tools.find((tool) => tool.functionId === 'calendly_create_calendar_event');

	// handle name change in the input
	const setToolName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: node.data.item.tools.map((tool: Tool) => {
									if (tool.functionId === 'calendly_create_calendar_event') {
										return {
											...tool,
											function: {
												...tool.function,
												name: e.target.value,
											},
										};
									}
									return tool;
								}),
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});
	};

	// handle description change in the input
	const setDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: node.data.item.tools.map((tool: Tool) => {
									if (tool.functionId === 'calendly_create_calendar_event') {
										return {
											...tool,
											function: {
												...tool.function,
												description: e.target.value,
											},
										};
									}
									return tool;
								}),
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});
	};

	return (
		<Card styles={{ body: { padding: 12 } }}>
			<div className="flex items-center justify-between gap-2">
				<h2 className="pb-2 text-lg font-bold text-black-7 dark:text-white">Book on Calendar (Calendly)</h2>

				<Space>
					<Button
						icon={
							<img
								src="/images/ai-assistant/delete-icon.svg"
								alt="delete-icon"
							/>
						}
						onClick={() => {
							props.handleDeleteTool('calendly_create_calendar_event');
						}}
					/>
				</Space>
			</div>

			<div>
				<div>
					<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Name</div>

					<Input
						size="large"
						defaultValue={tool ? (tool.function.name as string) : 'book_appointment'}
						onChange={setToolName}
					/>
				</div>

				<div className="pt-5" />

				<div>
					<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Description</div>

					<Input
						defaultValue={
							tool
								? (tool.function.description as string)
								: 'Book an appointment in the calendar. This function will book an appointment in the calendar for the given date and time.'
						}
						size="large"
						onChange={setDescription}
					/>
				</div>
			</div>
		</Card>
	);
};

export default BookOnCalendar;
