import { Button, Modal } from 'antd';
import { FC } from 'react';
import { IoPlayCircle, IoSwapVertical } from 'react-icons/io5';
import { MdOutlineCheck } from 'react-icons/md';
import { useThemeStore } from '../../store/theme';
import { Voice } from '../../types/ai-assistant.types';

type VoiceModalProps = {
	open: boolean;
	close: () => void;
	voices: Voice[];
	voice: Voice | null;
	setVoice: (value: Voice) => void;
};

const VoiceModal: FC<VoiceModalProps> = (props) => {
	const { is_dark_mode } = useThemeStore();

	return (
		<Modal
			title={<div className="text-center text-lg font-semibold">Choose Agent Voice</div>}
			open={props.open}
			onCancel={props.close}
			centered
			footer={null}
		>
			<div className="pt-5" />

			<div className="flex flex-col gap-8">
				{props.voices.map((voice, index) => (
					<div
						key={index}
						className="flex items-center justify-between"
					>
						<div className="flex items-center gap-3">
							<div className="relative">
								<img
									src={voice.avatar}
									alt="avatar"
									className="size-14 rounded-full object-cover"
								/>

								<div
									className="absolute -bottom-1 -left-1 rounded-full bg-white dark:bg-[#1f1f1f]"
									role="button"
								>
									<IoPlayCircle
										size={25}
										color={is_dark_mode ? 'white' : 'black'}
									/>
								</div>
							</div>
							<div>
								<div className="text-lg font-bold">{voice.name} </div>
								<div className="flex items-center gap-1 text-xs">
									<div className="capitalize">{voice.gender}</div>
									<div>&middot;</div>
									<div>Conversational</div>
								</div>
							</div>
						</div>
						{props.voice && props.voice.id === voice.id ? (
							<div>
								<MdOutlineCheck size={22} />
							</div>
						) : (
							<Button
								shape="round"
								size="large"
								icon={<IoSwapVertical />}
								iconPosition="end"
								type="primary"
								onClick={() => {
									props.setVoice(voice);
									props.close();
								}}
							>
								Choose
							</Button>
						)}
					</div>
				))}
			</div>
		</Modal>
	);
};

export default VoiceModal;
