import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
import { Alert, Button, Divider, Form, Input } from 'antd';
import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaGithub } from 'react-icons/fa6';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import axios from '../config/axios.ts';
import { AuthContext } from '../context/AuthContext';
import AuthLayout from '../layouts/auth.layout';

type FieldType = {
	name: string;
	email: string;
	password: string;
};

const Register = () => {
	const { setUser, setIsAuthenticated } = useContext(AuthContext);
	const [alertMessage, setAlertMessage] = useState<string | null>(null);
	const [values, setValues] = useState<FieldType | null>(null);
	const [hashedOtp, setHashedOtp] = useState<string | null>(null);
	const [otp, setOtp] = useState<string | null>(null);
	const [step, setStep] = useState(1);

	// create a counter for the timer to resend the code
	const [counter, setCounter] = useState(0);

	// resend code timer
	useEffect(() => {
		const timer = setTimeout(() => {
			if (counter > 0) {
				setCounter(counter - 1);
			}
		}, 1000);
		return () => clearTimeout(timer);
	}, [counter]);

	// login with Google
	const loginWithGoogle = useGoogleLogin({
		flow: 'auth-code',
		ux_mode: 'redirect',
		redirect_uri:
			import.meta.env.VITE_ENV === 'production'
				? 'https://app.puretalk.ai/redirect/'
				: 'https://puretalk-dashboard-rh5d8.ondigitalocean.app/redirect/',
	});

	// login with GitHub
	const loginWithGithub = () => {
		window.location.href =
			import.meta.env.VITE_ENV === 'production'
				? 'https://github.com/login/oauth/authorize?scope=user:email+read:user&client_id=Ov23li7qrngDDJ2TV8IW'
				: 'https://github.com/login/oauth/authorize?scope=user:email+read:user&client_id=9aeb4641c3dc541fa2c2';
	};

	// get otp by email mutation
	const { mutate: getOTP, isPending: getOTPPending } = useMutation({
		mutationFn: async (email: string) => {
			const { data } = await axios.post('/users/send-otp-email', { email });
			return data;
		},
		onSuccess: (data) => {
			setHashedOtp(data.results.hashed_otp);
			setStep(2);
			setAlertMessage(null);
		},
		onError: (error) => {
			if (error instanceof AxiosError && error.response && error.response.status === 429) {
				return setAlertMessage(error.response.data.detail);
			}

			if (error instanceof AxiosError && error.response && error.response.status === 404) {
				return setAlertMessage(error.response.data.msg);
			}

			if (error instanceof AxiosError && error.response && error.response.status === 500) {
				return setAlertMessage('Internal server error');
			}
		},
	});

	// register mutation
	const { mutate: register, isPending: registerPending } = useMutation({
		mutationFn: async (values: FieldType) => {
			const { data } = await axios.post('/users/email-registration', {
				...values,
				otp: otp,
				hashed_otp: hashedOtp,
			});
			return data;
		},
		onSuccess: (data) => {
			toast.success('Registration successful');
			window.localStorage.setItem('device_id', data.access_token);
			setUser(data.user);
			setIsAuthenticated(true);
		},
		onError: (error: AxiosError<{ msg: string }>) => {
			if (error.response) {
				setAlertMessage(error.response.data.msg);
			}
		},
	});

	// hide the error message after 3 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setAlertMessage(null);
		}, 3000);
		return () => clearTimeout(timer);
	}, [alertMessage]);

	return (
		<AuthLayout>
			<div className="h-full">
				<div className="flex flex-col items-center justify-center">
					<Link to={'/'}>
						<img
							src="/images/logo.png"
							alt="logo"
							className="h-12"
						/>
					</Link>

					<div className="pt-10" />

					{step === 1 ? (
						<h1 className="text-center text-4xl font-bold text-black-7 dark:text-white">Let’s join with us</h1>
					) : (
						<h1 className="text-center text-4xl font-bold text-black-7 dark:text-white">Verify your email</h1>
					)}

					<div className="pt-4" />

					{step === 1 ? (
						<p className="text-center text-base font-medium text-gray-400 dark:text-gray-200">
							Just pop your name, email and password in here
						</p>
					) : (
						values && (
							<p className="text-center text-base font-medium text-gray-400 dark:text-gray-200">
								A verification code was sent to <span className={'text-primary'}>{values.email}</span>
							</p>
						)
					)}

					<div className="pt-6" />

					{step === 1 && (
						<div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
							<Button
								block
								size="large"
								className="h-11 px-12 font-bold text-black-7 dark:text-white"
								icon={<FcGoogle size={20} />}
								onClick={() => loginWithGoogle()}
								shape="round"
							>
								Sign up with Google
							</Button>

							<Button
								block
								size="large"
								className="h-11 px-12 font-bold text-black-7 dark:text-white"
								icon={<FaGithub size={20} />}
								onClick={() => loginWithGithub()}
								shape="round"
							>
								Sign up with Github
							</Button>
						</div>
					)}

					{step === 1 && (
						<Divider>
							<span className="font-medium text-black-7 dark:text-white">Or</span>
						</Divider>
					)}

					{step === 1 && <div className="pt-2" />}

					{alertMessage && (
						<Alert
							message={alertMessage}
							type={alertMessage.includes('successful') ? 'success' : 'error'}
							showIcon
							className="w-full"
						/>
					)}

					{step === 2 && <div className="pt-3" />}

					{step === 1 && (
						<Form
							onFinish={(values) => {
								setValues(values);
								getOTP(values.email);
							}}
							layout="vertical"
							className="w-full"
							requiredMark={false}
						>
							<Form.Item<FieldType>
								label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Name</div>}
								name="name"
								className="mb-4"
							>
								<Input
									size="large"
									placeholder="Enter your name"
									required
									className="h-11"
								/>
							</Form.Item>

							<Form.Item<FieldType>
								label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Email</div>}
								name="email"
								className="mb-4"
							>
								<Input
									size="large"
									placeholder="Enter your email"
									required
									type="email"
									className="h-11"
								/>
							</Form.Item>

							<Form.Item<FieldType>
								label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Password</div>}
								name="password"
								className="mb-6"
							>
								<Input.Password
									size="large"
									placeholder="Enter your password"
									required
									type="password"
									className="h-11"
								/>
							</Form.Item>

							<Form.Item className="mb-2">
								<Button
									htmlType="submit"
									block
									size="large"
									shape="round"
									className="h-11 font-bold"
									type="primary"
									loading={getOTPPending}
									disabled={getOTPPending}
								>
									Send Verification Code
								</Button>
							</Form.Item>
						</Form>
					)}

					{step === 2 && (
						<div className="w-full">
							<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Verification Code</div>

							<div className="pt-2" />

							<Input
								size="large"
								onChange={(e) => {
									setOtp(e.target.value);
								}}
								value={otp as string}
								autoFocus
								className="h-11 w-full"
								placeholder="Enter verification code"
								type="number"
								inputMode="numeric"
							/>

							<div className="pt-6" />

							<div className="flex flex-wrap items-center justify-between gap-2">
								<div className="text-base font-medium text-black-7 dark:text-white">
									Can’t find the code? Check your spam folder or
								</div>

								{counter > 0 ? (
									<motion.div
										key={counter}
										className={'text-base font-medium text-black-7 dark:text-white'}
										initial={{ opacity: 0.5 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0.5 }}
										transition={{ duration: 1 }}
									>
										00:{counter.toString().padStart(2, '0')}
									</motion.div>
								) : (
									<button
										className={'text-base font-medium text-[#6E5DED]'}
										onClick={() => {
											setCounter(59);
											if (values) {
												getOTP(values.email);
											}
										}}
									>
										Resend Code
									</button>
								)}
							</div>

							<div className="pt-6" />

							<Button
								htmlType="submit"
								block
								size="large"
								shape="round"
								className="h-11 font-bold"
								type="primary"
								onClick={() => {
									register(values as FieldType);
								}}
								loading={registerPending}
								disabled={registerPending}
							>
								Sign Up
							</Button>
						</div>
					)}

					<div className="pt-3" />

					<div className="text-center">
						<span className="txt-base text-gray-400 dark:text-gray-200">Have an account?</span>
						<Link
							to={'/login'}
							className="ml-1 font-medium text-[#6E5DED]"
						>
							Sign in
						</Link>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
};

export default Register;
