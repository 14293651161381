import { CloseOutlined } from '@ant-design/icons';
import { Avatar, Drawer } from 'antd';
import { FC, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import SidebarBottom from '../../layouts/sidebar-bottom';

type Props = {
	open: boolean;
	close: () => void;
};

const SidebarDrawer: FC<Props> = (props) => {
	const { user } = useContext(AuthContext);

	return (
		<Drawer
			title={
				<div className="flex items-center justify-between">
					<Avatar
						shape="circle"
						src={user && user.dp}
					/>

					<div
						role="button"
						onClick={props.close}
					>
						<CloseOutlined />
					</div>
				</div>
			}
			onClose={props.close}
			open={props.open}
			placement="left"
			width={'18rem'}
			closable={false}
			styles={{ body: { padding: 0 } }}
		>
			<SidebarBottom />
		</Drawer>
	);
};

export default SidebarDrawer;
