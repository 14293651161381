import { Card, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { FC, Fragment, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import axios from '../../config/axios';
import { cn } from '../../config/cn';
import { useThemeStore } from '../../store/theme';
import { colors } from '../../theme/colors';
import { formatSeconds } from '../../utils/helpers';

type Data = {
	date: string;
	'Incoming Calls': number;
	'Outgoing Calls': number;
};

type DashboardItem = {
	imgLight: string;
	imgDark: string;
	altText: string;
	title: string;
	up: boolean;
	count: string | number | undefined;
	percentage: number | undefined;
};

type CallsAnalyticsProps = {
	dateRange: [string, string];
};

export type CallReport = {
	total_calls_made: number;
	total_incoming_calls: number;
	total_outgoing_calls: number;
	web_calls: number;
	voip_calls: number;
	total_seconds: number;
};

export type CallSummary = CallReport & {
	date: string;
};

const getCallReport = async (dateRange: [string, string]) => {
	const { data } = await axios.get('/reports/call-report', {
		withCredentials: true,
		params: {
			start: dateRange[0],
			end: dateRange[1],
		},
	});
	return data.results;
};

const compareAllPercentages = async (dateRange: [string, string]) => {
	const currentMonthData = await getCallReport(dateRange);
	const lastMonthData = await getCallReport([
		dayjs(dateRange[0]).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
		dayjs(dateRange[1]).subtract(1, 'month').format('YYYY-MM-DD'),
	]);

	const keys = ['total_calls_made', 'total_incoming_calls', 'total_outgoing_calls', 'total_seconds'];
	const results = {} as Record<string, { percentageChange: number; direction: 'up' | 'down' }>;

	keys.forEach((key) => {
		const currentMonthValue = currentMonthData[key];
		const lastMonthValue = lastMonthData[key];
		let percentageChange = 0;
		let direction: 'up' | 'down' = 'down';

		if (lastMonthValue === 0) {
			percentageChange = currentMonthValue > 0 ? 100 : 0;
			direction = currentMonthValue > 0 ? 'up' : 'down';
		} else {
			percentageChange = Math.round(Math.abs(((currentMonthValue - lastMonthValue) / lastMonthValue) * 100));
			direction = currentMonthValue > lastMonthValue ? 'up' : 'down';
		}

		results[key] = { percentageChange, direction };
	});

	return results;
};

const CallsAnalytics: FC<CallsAnalyticsProps> = (props) => {
	const { is_dark_mode } = useThemeStore();
	const isCurrentMonth =
		props.dateRange[0] === dayjs().startOf('month').format('YYYY-MM-DD') && props.dateRange[1] === dayjs().format('YYYY-MM-DD');
	const [callReportLoading, setCallReportLoading] = useState(true);
	const [dashboardItems, setDashboardItems] = useState<DashboardItem[]>(() => [
		{
			imgLight: '/images/dashboard/total-calls-made-light.svg',
			imgDark: '/images/dashboard/total-calls-made-dark.svg',
			altText: 'total-calls-made',
			title: 'Total Calls Made',
			up: false,
			count: 0,
			percentage: 0,
		},
		{
			imgLight: '/images/dashboard/total-calls-received-light.svg',
			imgDark: '/images/dashboard/total-calls-received-dark.svg',
			altText: 'total-calls-received',
			title: 'Total Calls Received',
			up: false,
			count: 0,
			percentage: 0,
		},
		{
			imgLight: '/images/dashboard/total-calls-outgoing-light.svg',
			imgDark: '/images/dashboard/total-calls-outgoing-dark.svg',
			altText: 'total-calls-outgoing',
			title: 'Total Outgoing Calls',
			up: false,
			count: 0,
			percentage: 0,
		},
		{
			imgLight: '/images/dashboard/call-volumes-light.svg',
			imgDark: '/images/dashboard/call-volumes-dark.svg',
			altText: 'call-volumes',
			title: 'Call Volumes',
			up: false,
			count: 0,
			percentage: 0,
		},
	]);
	const [last10daysDataLoading, setLast10DaysDataLoading] = useState(true);
	const [last10DaysData, setLast10DaysData] = useState<{
		total_calls_made: number;
		up: boolean;
		percentage: number;
	}>({
		total_calls_made: 0,
		up: false,
		percentage: 0,
	});
	const [chartData, setChartData] = useState<Data[]>([]);
	const { width } = useWindowSize();

	// show calls analytics
	useEffect(() => {
		(async () => {
			try {
				setCallReportLoading(true);
				const callReportPromise = getCallReport(props.dateRange);
				let comparisonDataPromise;

				//  if the date range matches the start of the month to the current date
				if (isCurrentMonth) {
					comparisonDataPromise = compareAllPercentages(props.dateRange);
				} else {
					comparisonDataPromise = Promise.resolve(null);
				}

				// get the call report data and comparison data
				const callReportData = await callReportPromise;
				const comparisonResults = await comparisonDataPromise;

				// update the dashboard items
				setDashboardItems((prev) =>
					prev.map((item, index) => {
						const keys = ['total_calls_made', 'total_incoming_calls', 'total_outgoing_calls', 'total_seconds'];
						const key = keys[index];
						return {
							...item,
							count: key === 'total_seconds' ? formatSeconds(callReportData[key]) : callReportData[key],
							percentage: comparisonResults?.[key]?.percentageChange,
							up: comparisonResults?.[key]?.direction === 'up',
						};
					}),
				);
			} catch (error) {
				console.error(error);
			} finally {
				setCallReportLoading(false);
			}
		})();
	}, [isCurrentMonth, props.dateRange]);

	// show chart data, use last 10 days for total call and comparison
	useEffect(() => {
		(async () => {
			try {
				setLast10DaysDataLoading(true);

				// first get the last 10 days data
				const last10DaysData = await getCallReport([dayjs().subtract(9, 'days').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);

				// get total calls made
				const totalCallsMade = last10DaysData.total_calls_made;

				// Calculate the start date for the last 10 days from today
				const today = dayjs();
				const startThisPeriod = today.subtract(9, 'days');

				// Calculate the corresponding dates for the previous month
				const startLastMonth = startThisPeriod.subtract(1, 'month');
				const endLastMonth = today.subtract(1, 'month');
				const lastMonthData = await getCallReport([startLastMonth.format('YYYY-MM-DD'), endLastMonth.format('YYYY-MM-DD')]);
				const lastMonthTotalCallsMade = lastMonthData.total_calls_made;

				// calculate percentage change
				let percentageChange = 0;

				if (lastMonthTotalCallsMade !== 0) {
					percentageChange = Math.round(Math.abs(((totalCallsMade - lastMonthTotalCallsMade) / lastMonthTotalCallsMade) * 100));
				} else {
					percentageChange = totalCallsMade > 0 ? 100 : 0;
				}

				setLast10DaysData({
					total_calls_made: totalCallsMade,
					up: totalCallsMade > lastMonthTotalCallsMade,
					percentage: percentageChange,
				});

				// get the chart data
				const { data } = await axios.get('/reports/call-summary', { withCredentials: true });
				const results: CallSummary[] = data.results;

				setChartData(
					results.map((result) => ({
						date: result.date,
						'Incoming Calls': result.total_incoming_calls,
						'Outgoing Calls': result.total_outgoing_calls,
					})),
				);
			} catch (error) {
				console.error(error);
			} finally {
				setLast10DaysDataLoading(false);
			}
		})();
	}, []);

	return (
		<Fragment>
			<div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:flex">
				{dashboardItems.map((item, index) => (
					<Fragment key={index}>
						<div className="flex gap-3.5">
							<img
								src={is_dark_mode ? item.imgDark : item.imgLight}
								alt={item.altText}
								className="size-11"
							/>
							<div>
								<div className="text-base font-medium text-black-3 dark:text-[#8a8990]">{item.title}</div>

								<div className="pt-3" />

								{callReportLoading ? (
									<Skeleton.Button
										active
										className="!w-52 lg:!w-36 2xl:!w-52"
									/>
								) : (
									<div className="text-2xl font-semibold leading-none text-black-7 dark:text-white"> {item.count}</div>
								)}

								<div className="pt-3" />

								{callReportLoading && isCurrentMonth ? (
									<Skeleton
										active
										paragraph={false}
										className="!w-56 lg:!w-36 2xl:!w-56"
									/>
								) : (
									isCurrentMonth && (
										<div className="flex flex-row gap-2 lg:flex-col lg:gap-0 min-[1370px]:flex-row min-[1370px]:gap-2">
											<div className="flex items-center gap-2">
												<img
													src={`/images/dashboard/graph-${item.up ? 'up' : 'down'}.svg`}
													alt={`graph-${item.up ? 'up' : 'down'}`}
												/>

												<div
													className={cn('text-sm font-bold', {
														'text-green-600': item.up,
														'text-red-600': !item.up,
													})}
												>
													{item.up ? '+' : '-'}
													{item.percentage}%
												</div>
											</div>

											<div className="text-sm font-medium text-black-3 dark:text-[#8a8990]">(Compared to last month)</div>
										</div>
									)
								)}
							</div>
						</div>

						{index !== dashboardItems.length - 1 && <div className="hidden border lg:block dark:border-[#2d2b38]" />}
					</Fragment>
				))}
			</div>

			<div className="pt-9" />

			<Card
				className="h-full"
				styles={{ body: { padding: 15 } }}
			>
				<div className="flex items-center justify-between gap-2">
					<h2 className="text-xl font-semibold text-black-7 dark:text-white">Last 10 Days Calls</h2>
				</div>

				<div className="pt-4" />

				<div>
					<div className="text-base font-medium text-black-3 dark:text-[#8a8990]">Total Calls</div>

					<div className="pt-3" />

					{last10daysDataLoading ? (
						<Skeleton.Button
							active
							className="!w-52 lg:!w-36 2xl:!w-52"
						/>
					) : (
						<div className="text-2xl font-bold leading-none text-black-7 dark:text-white">{last10DaysData.total_calls_made}</div>
					)}

					<div className="pt-3" />

					{last10daysDataLoading ? (
						<Skeleton
							active
							paragraph={false}
							className="!w-56 lg:!w-36 2xl:!w-56"
						/>
					) : (
						<div className="flex items-center gap-2">
							<img
								src={`/images/dashboard/graph-${last10DaysData.up ? 'up' : 'down'}.svg`}
								alt={`graph-${last10DaysData.up ? 'up' : 'down'}`}
							/>

							<div
								className={cn('text-sm font-bold', {
									'text-green-600': last10DaysData.up,
									'text-red-600': !last10DaysData.up,
								})}
							>
								{last10DaysData.up ? '+' : '-'}
								{last10DaysData.percentage}%
							</div>

							<div className="text-sm font-medium text-black-3 dark:text-[#8a8990]">(Compared to last month)</div>
						</div>
					)}
				</div>

				<div className="pt-6" />

				<div>
					{last10daysDataLoading ? (
						<div className="flex h-60 items-center justify-center lg:h-96">
							<img
								src={is_dark_mode ? '/images/dashboard/chart-loading-dark.svg' : '/images/dashboard/chart-loading-light.svg'}
								alt="chart-loading"
								className="size-10"
							/>
						</div>
					) : (
						<ResponsiveContainer
							width="100%"
							{...(width > 1000
								? { aspect: 4 / 1 }
								: {
										height: 220,
									})}
						>
							<AreaChart
								data={chartData}
								margin={{
									top: 5,
									right: 30,
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date" />
								<YAxis />
								<Tooltip
									contentStyle={{
										color: is_dark_mode ? 'white' : 'black',
										backgroundColor: is_dark_mode ? '#161422' : 'white',
										border: is_dark_mode ? '1px solid #2d2b38' : '1px solid #f0f0f0',
										borderRadius: 8,
									}}
								/>
								<Legend />
								<Area
									type="bump"
									dataKey="Incoming Calls"
									stroke="#6e5ded"
									fill={colors.primary}
									activeDot={{ r: 8 }}
								/>
								<Area
									type="bump"
									dataKey="Outgoing Calls"
									stroke="#b18eed"
								/>
							</AreaChart>
						</ResponsiveContainer>
					)}
				</div>
			</Card>

			<div className="pb-8" />
		</Fragment>
	);
};

export default CallsAnalytics;
