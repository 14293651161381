import { CloudUploadOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Avatar, Button, Input, Modal, Skeleton, Spin, Upload } from 'antd';
import { FC, useState } from 'react';
import { BsSoundwave } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { CiImageOn } from 'react-icons/ci';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { IoChevronForwardSharp } from 'react-icons/io5';
import { Fragment } from 'react/jsx-runtime';
import axios from '../../config/axios';
import { Voice } from '../../types/ai-assistant.types';
import FormLabel from './form-label';
import { Tab, tabs } from './tabs-data';
import VoiceModal from './voice-modal';

type IdentityFormProps = {
	name: string;
	setName: (value: string) => void;
	voice: Voice | null;
	setVoice: (value: Voice | null) => void;
	avatar: string;
	setAvatar: (value: string) => void;
	setActiveTab: (value: Tab) => void;
};

const IdentityForm: FC<IdentityFormProps> = (props) => {
	const [showVoicesModal, setShowVoicesModal] = useState<boolean>(false);
	const [uploading, setUploading] = useState<boolean>(false);

	// get public voice
	const { data, isLoading } = useQuery<Voice[]>({
		queryKey: ['public-voices'],
		queryFn: async () => {
			const { data } = await axios.get('/voices', { withCredentials: true });

			// set first voice as default
			props.setVoice(data.results[0]);

			return data.results;
		},
		refetchOnWindowFocus: false,
	});

	// handle next button click
	const handleNextButtonClick = () => {
		if (!props.name) {
			return Modal.error({
				title: 'Name is required',
				content: 'Please give your agent a name.',
				centered: true,
			});
		}

		props.setActiveTab(tabs[1]);
	};

	return (
		<Fragment>
			<div>
				<FormLabel
					label="NAME"
					icon={<CgProfile size={20} />}
				/>

				<div className="pt-3" />

				<Input
					placeholder="Give your agent a name"
					size="large"
					value={props.name}
					onChange={(e) => props.setName(e.target.value)}
				/>
			</div>

			<div className="pt-6" />

			<div>
				<FormLabel
					label="VOICE"
					icon={<BsSoundwave size={20} />}
				/>

				<div className="pt-3" />

				{isLoading ? (
					<Skeleton.Input
						active
						size="large"
						block
					/>
				) : props.voice ? (
					<div
						className="flex h-10 items-center justify-between gap-2 rounded-lg border px-3"
						role="button"
						onClick={() => setShowVoicesModal(true)}
					>
						<div className="flex items-center gap-3">
							<img
								src={props.voice.avatar}
								alt="avatar"
								className="size-6 rounded-full object-cover"
							/>
							<div className="text-base font-semibold text-gray-700 dark:text-gray-200">{props.voice.name}</div>
						</div>
						<div>
							<HiOutlineChevronDown size={20} />
						</div>
					</div>
				) : null}
			</div>

			<div className="pt-6" />

			<div>
				<FormLabel
					label="AVATAR"
					icon={<CiImageOn size={20} />}
				/>

				<div className="pt-3" />

				<div className="grid grid-cols-1 gap-4">
					<Upload
						className="[&>div]:w-full"
						showUploadList={false}
						customRequest={async ({ file }) => {
							try {
								setUploading(true);
								const formData = new FormData();
								formData.append('image', file as File);

								// upload image
								const { data } = await axios.post('/commons/upload-image', formData, { withCredentials: true });

								// set avatar
								props.setAvatar(data.results.secure_url);
								console.log(file);
							} catch (error) {
								console.error(error);
							} finally {
								setUploading(false);
							}
						}}
					>
						<Button
							icon={<CloudUploadOutlined />}
							block
							size="large"
						>
							Upload Image
						</Button>
					</Upload>
				</div>

				<div className="pt-3" />

				<div className="w-fit">
					<Spin
						spinning={uploading}
						size="small"
					>
						<Avatar
							src={props.avatar}
							size={60}
						/>
					</Spin>
				</div>
			</div>

			<div className="pt-6" />

			<Button
				size="large"
				type="primary"
				block
				icon={<IoChevronForwardSharp />}
				iconPosition="end"
				onClick={handleNextButtonClick}
			>
				Next
			</Button>

			{/* voices modal */}
			{data && (
				<VoiceModal
					open={showVoicesModal}
					close={() => setShowVoicesModal(false)}
					voices={data}
					voice={props.voice}
					setVoice={props.setVoice}
				/>
			)}
		</Fragment>
	);
};

export default IdentityForm;
