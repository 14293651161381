import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, Popover, Select, Tag } from 'antd';
import { FC, Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios.ts';
import { cn } from '../../config/cn.ts';
import { useThemeStore } from '../../store/theme.ts';
import { AIAssistant } from '../../types/ai-assistant.types.ts';
import MultiPromptTree from '../multi-prompt-tree/multi-prompt-tree.tsx';

type UpdateAssistantProps = {
	assistant: AIAssistant;
	assistantId: string;
	refetch: () => void;
	refetchAssistant: () => void;
};

const UpdateAssistant: FC<UpdateAssistantProps> = ({ assistant, assistantId, refetch, refetchAssistant }) => {
	const { is_dark_mode } = useThemeStore();
	const [model_form] = Form.useForm();
	const who_speaks = Form.useWatch('who_speaks_first', model_form);
	const prompt = Form.useWatch('prompt', model_form);
	const begin_message = Form.useWatch('begin_message', model_form);
	const [openFlow, setOpenFlow] = useState(false);
	const [promptVariables, setPromptVariables] = useState<string[]>([]);
	const [beginMessageVariables, setBeginMessageVariables] = useState<string[]>([]);

	// update assistant mutation
	const { mutate: updateAssistant, isPending: updateAssistantPending } = useMutation({
		mutationFn: async (payload: AIAssistant) => {
			const { data } = await axios.patch(`/ai-assistants/${assistantId}`, payload, { withCredentials: true });

			return data;
		},
		onSuccess: () => {
			toast.success('Assistant updated successfully');
			refetch();
			refetchAssistant();
		},
	});

	// set initial values
	useEffect(() => {
		model_form.setFieldsValue({
			prompt: assistant.prompt,
			model: assistant.model,
			who_speaks_first: assistant.who_speaks_first,
			begin_message: assistant.begin_message,
			custom_knowledge_base: assistant.custom_knowledge_base,
		});
	}, [assistant, model_form]);

	// look for variables in the prompt and add them to the variables array
	useEffect(() => {
		if (!prompt) return;
		const regex = /{{(.*?)}}/g;
		const matches = prompt.match(regex);

		if (matches) {
			const vars = matches.map((m: string) => m.replace(/{{|}}/g, ''));
			// remove duplicates
			const uniqueVars = [...new Set(vars)] as string[];
			setPromptVariables(uniqueVars);
		}
	}, [prompt]);

	// look for variables in the begin message and add them to the variables array
	useEffect(() => {
		if (!begin_message) return;
		const regex = /{{(.*?)}}/g;
		const matches = begin_message.match(regex);

		if (matches) {
			const vars = matches.map((m: string) => m.replace(/{{|}}/g, ''));
			// remove duplicates
			const uniqueVars = [...new Set(vars)] as string[];
			setBeginMessageVariables(uniqueVars);
		}
	}, [begin_message]);

	return (
		<Fragment>
			<h2 className="text-xl font-semibold text-black-7 dark:text-white">Model</h2>

			<div className="pt-4" />

			<Form
				layout="vertical"
				onFinish={(values) => {
					updateAssistant(values);
				}}
				form={model_form}
				requiredMark={false}
			>
				<div className="flex flex-wrap items-center justify-between gap-4">
					<div className="max-w-lg font-medium text-black-3">
						Write general task for the assistant to perform. This is the main task that the assistant will perform when it is called upon.
					</div>

					<Form.Item className="mb-0">
						<div className="flex items-center gap-2">
							<Button
								icon={
									<img
										src={is_dark_mode ? '/images/ai-assistant/clear-icon-dark.svg' : '/images/ai-assistant/clear-icon.svg'}
										alt="clear-icon"
									/>
								}
								className="font-semibold"
								size="large"
								onClick={() => {
									model_form.setFieldsValue({
										prompt: '',
										begin_message: '',
										custom_knowledge_base: '',
									});
								}}
							>
								Clear
							</Button>

							<Button
								type="primary"
								htmlType="submit"
								className="font-semibold"
								size="large"
								disabled={updateAssistantPending}
								loading={updateAssistantPending}
								icon={
									<img
										src="/images/ai-assistant/save-icon.svg"
										alt="save-icon"
									/>
								}
							>
								Save
							</Button>
						</div>
					</Form.Item>
				</div>

				<div className="pt-6" />

				<Form.Item
					label={
						<section>
							<div className="text-base font-bold">General Task</div>

							<div className="flex flex-wrap items-center gap-2">
								<div>You can add variables to make the task more dynamic.</div>
								<Popover
									content={
										<div className="space-y-1">
											<div className="font-medium">
												1. You can add variables to the task by using the following format:{' '}
												<span className="font-semibold text-black-3">{'{{variable_name}}'}</span>
											</div>
											<div className="font-medium">
												2. You can use single word variables like{' '}
												<span className="font-semibold text-black-3">{'{{username}}'}</span>
											</div>
											<div className="font-medium">
												3. Use underscores to separate words in the multi word variable name. For example,{' '}
												<span className="font-semibold text-black-3">{'{{first_name}}'}</span>
											</div>
										</div>
									}
									arrow={false}
									placement="bottom"
									overlayClassName="w-96 md:w-auto"
								>
									<div
										className="font-bold text-primary"
										role="button"
									>
										Guidelines
									</div>
								</Popover>
							</div>
						</section>
					}
					name="prompt"
					extra={
						promptVariables.length && (
							<div className="flex gap-1 pt-3 text-base">
								<div className="font-medium text-black-3">Variables: </div>
								<div className="flex flex-wrap gap-3">
									{promptVariables.map((variable, index) => (
										<Tag
											color={is_dark_mode ? '#1d1d1d' : '#2db7f5'}
											key={index}
											className="mr-0 text-base"
										>
											{variable}
										</Tag>
									))}
								</div>
							</div>
						)
					}
				>
					<Input.TextArea
						required
						placeholder="Type in details of the prompt here..."
						rows={12}
						styles={{
							textarea: {
								fontSize: 15,
								scrollbarWidth: 'thin',
								background: is_dark_mode ? '#1b1827' : '#F9FAFB',
							},
						}}
					/>
				</Form.Item>

				<Form.Item
					label={<div className="text-base font-bold">Custom Knowledge Base</div>}
					name="custom_knowledge_base"
				>
					<Input.TextArea
						placeholder="Type in details of the custom knowledge base here..."
						rows={6}
						styles={{
							textarea: {
								fontSize: 15,
								scrollbarWidth: 'thin',
								background: is_dark_mode ? '#1b1827' : '#F9FAFB',
							},
						}}
					/>
				</Form.Item>

				{assistant.assistant_type === 'multi_prompt' && (
					<div className={'mb-4'}>
						<div className="pb-2 text-base font-bold">Multi Task Tree</div>
						<div
							className={cn('relative flex h-64 items-center justify-center rounded-lg border dark:border-[#322f3d]', {
								'bg-[url(/images/ai-assistant/multi-task-tree-bg-dark.svg)]': is_dark_mode,
								'bg-[url(/images/ai-assistant/multi-task-tree-bg.svg)]': !is_dark_mode,
							})}
							onClick={() => setOpenFlow(true)}
							role={'button'}
						>
							<img
								src={
									is_dark_mode
										? '/images/ai-assistant/multi-task-tree-nodes-dark.svg'
										: '/images/ai-assistant/multi-task-tree-nodes.svg'
								}
								alt="multi-task-tree-nodes"
							/>

							<div className={'absolute right-5 top-5'}>
								<img
									src={
										is_dark_mode
											? '/images/ai-assistant/multi-task-tree-update-icon-dark.svg'
											: '/images/ai-assistant/multi-task-tree-update-icon.svg'
									}
									alt="multi-task-tree-update-icon"
								/>
							</div>
						</div>
					</div>
				)}

				<div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
					<Form.Item
						label={<div className="text-base font-bold">Model</div>}
						name="model"
					>
						<Select
							options={[{ label: 'Puretalk PLG', value: 'puretalk-plg' }]}
							placeholder="Select Model"
							size="large"
							className="[&>.ant-select-selector]:!bg-input-bg dark:[&>.ant-select-selector]:!bg-[#1b1827]"
						/>
					</Form.Item>

					<Form.Item
						label={<div className="text-base font-bold">Who Speaks First</div>}
						name="who_speaks_first"
					>
						<Select
							options={[
								{ label: 'Assistant', value: 'assistant' },
								{ label: 'User', value: 'user' },
							]}
							placeholder="Who Speaks First"
							size="large"
							className="[&>.ant-select-selector]:!bg-input-bg dark:[&>.ant-select-selector]:!bg-[#1b1827]"
						/>
					</Form.Item>
				</div>

				{who_speaks === 'assistant' && (
					<Form.Item
						label={
							<section>
								<div className="text-base font-bold">Begin Message</div>

								<div>
									You can add variables to make the begin message more dynamic. Follow the guidelines in the General Task section.
								</div>
							</section>
						}
						name="begin_message"
						extra={
							beginMessageVariables.length && (
								<div className="flex gap-1 pt-3 text-base">
									<div className="font-medium text-black-3">Variables: </div>
									<div className="flex flex-wrap gap-3">
										{beginMessageVariables.map((variable, index) => (
											<Tag
												color={is_dark_mode ? '#1d1d1d' : '#2db7f5'}
												key={index}
												className="mr-0 text-base"
											>
												{variable}
											</Tag>
										))}
									</div>
								</div>
							)
						}
					>
						<Input
							placeholder="Begin Message"
							size="large"
							required
							className="bg-input-bg dark:bg-[#1b1827]"
						/>
					</Form.Item>
				)}
			</Form>

			{/*	multi prompt tree */}
			<MultiPromptTree
				open={openFlow}
				close={() => setOpenFlow(false)}
				assistant={assistant}
				refetchAssistant={refetchAssistant}
			/>
		</Fragment>
	);
};

export default UpdateAssistant;
