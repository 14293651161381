import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import { Card as AntdCard, Button, Switch } from 'antd';
import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import { FormEvent, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { BsDashLg } from 'react-icons/bs';
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2';
import { IoCheckmark, IoCheckmarkSharp } from 'react-icons/io5';
import { MdAddCard, MdOutlineRadioButtonChecked } from 'react-icons/md';
import { Card } from '../components/billing/payment-methods';
import axios from '../config/axios';
import { cn } from '../config/cn';
import { AuthContext } from '../context/AuthContext';
import { cardTypeToImage, subscriptionPlans } from '../data/data';
import AppLayout from '../layouts/app.layout';
import { stripePromise } from '../lib/stripe';

// main component
const Upgrade = () => (
	<Elements stripe={stripePromise}>
		<UpgradeComponent />
	</Elements>
);

export default Upgrade;

// wrapped component to use Elements
const UpgradeComponent = () => {
	const [activeView, setActiveView] = useState<'pricing' | 'comparison' | 'payment' | 'payment_methods'>('pricing');
	const [selectedPlanType, setSelectedPlanType] = useState<'monthly' | 'yearly'>('monthly');
	const { user } = useContext(AuthContext);
	const [clientSecret, setClientSecret] = useState<string | null>(null);
	const [paymentMethods, setPaymentMethods] = useState<Card[]>([]);
	const [selectedCard, setSelectedCard] = useState<Card | null>(null);
	const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
	const [loading, setLoading] = useState({
		subscription: false,
		payment: false,
	});

	// Initialize an instance of stripe.
	const stripe = useStripe();
	const elements = useElements();

	// reload the page after 2 seconds
	const reloadPage = () => {
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};

	// handle subscription
	const handleSubscription = async (plan: string) => {
		try {
			setLoading((prev) => ({ ...prev, subscription: true }));

			// check if user has saved payment method
			const { data: paymentMethods } = await axios.get('/billing/payment-methods', { withCredentials: true });

			// if user has saved payment methods
			if (paymentMethods.results.length > 0) {
				setPaymentMethods(paymentMethods.results);
				setActiveView('payment_methods');
				return;
			}

			// if user does not have saved payment methods
			const { data } = await axios.post('/billing/subscription', { package: plan }, { withCredentials: true });
			setClientSecret(data.results.client_secret);
			setActiveView('payment');
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('An error occurred while processing your request.');
			}
		} finally {
			setLoading((prev) => ({ ...prev, subscription: false }));
		}
	};

	// handle payment
	const handlePayment = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!stripe || !elements) return null;

		const cardElement = elements.getElement(CardElement);

		if (!clientSecret) return null;

		setLoading((prev) => ({ ...prev, payment: true }));

		const { error } = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: cardElement as StripeCardElement,
			},
		});

		if (error) {
			return toast.error('An error occurred while processing your payment.');
		}

		setLoading((prev) => ({ ...prev, payment: false }));

		toast.success('Payment successful. Reloading page...');

		// reload the page
		reloadPage();
	};

	// handle payment with saved method
	const handlePaymentWithSavedMethod = async () => {
		if (!selectedCard) {
			return toast.error('Please select a payment method.');
		}

		try {
			setLoading((prev) => ({ ...prev, payment: true }));

			// create subscription and get client secret
			const { data } = await axios.post(
				'/billing/subscription',
				{ package: selectedPlan, payment_method: selectedCard.id },
				{ withCredentials: true },
			);
			const { client_secret } = data.results;

			if (!stripe) return null;

			const { error } = await stripe.confirmCardPayment(client_secret);

			if (error) {
				return toast.error('An error occurred while processing your payment.');
			}

			toast.success('Payment successful. Reloading page...');

			// reload the page
			reloadPage();
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('An error occurred while processing your request.');
			}
		} finally {
			setLoading((prev) => ({ ...prev, payment: false }));
		}
	};

	// handle new payment method
	const handleNewPaymentMethod = async () => {
		try {
			setLoading((prev) => ({ ...prev, subscription: true }));
			const { data } = await axios.post('/billing/subscription', { package: selectedPlan }, { withCredentials: true });
			setClientSecret(data.results.client_secret);
			setActiveView('payment');
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				return toast.error(error.response.data.msg);
			}

			toast.error('An error occurred while processing your request.');
		} finally {
			setLoading((prev) => ({ ...prev, subscription: false }));
		}
	};

	// cancel subscription
	const { mutate: cancelSubscription, isPending: cancelSubscriptionLoading } = useMutation({
		mutationFn: async () => {
			await axios.delete('/billing/subscription/cancel', { withCredentials: true });
		},
		onSuccess: () => {
			toast.success('Subscription cancelled. Reloading page...');

			// reload the page
			reloadPage();
		},
		onError: (error) => {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('An error occurred while processing your request.');
			}
		},
	});

	return (
		<AppLayout
			title="Upgrade"
			subtitle="Upgrade your account to unlock more features."
		>
			<div className="mx-auto max-w-screen-lg">
				{activeView === 'pricing' && (
					<motion.div
						initial={{ y: 10, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -10, opacity: 0 }}
						transition={{ duration: 0.2 }}
					>
						<div className="flex flex-wrap items-center justify-between gap-2">
							<div>
								<div className="text-xl font-bold text-black-7 dark:text-white">Plans & Pricing </div>
								<div className="text-base font-medium text-gray-600 dark:text-gray-300">
									Simple Pricing. No Hidden Fees. Advanced Features for your business.
								</div>
							</div>

							<div className="flex items-center gap-3">
								<div className="font-semibold text-gray-600 dark:text-gray-200">Monthly</div>
								<Switch
									checked={selectedPlanType === 'yearly'}
									onChange={(checked) => {
										setSelectedPlanType(checked ? 'yearly' : 'monthly');
									}}
								/>
								<div>
									<div className="font-semibold text-gray-600 dark:text-gray-200">Yearly</div>
									<div className="text-sm text-gray-500 dark:text-gray-300">20% OFF</div>
								</div>
							</div>
						</div>

						<div className="pt-7" />

						<div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
							{subscriptionPlans.map((plan, index) => (
								<div
									className="rounded-xl border border-transparent bg-gray-50 p-6 dark:border-[#2d2b38] dark:bg-dark-bg"
									key={index}
								>
									<div className="flex gap-2">
										<div className="text-2xl font-bold capitalize text-black-7 dark:text-white">{plan.name}</div>
										{plan.discountPercent > 0 && (
											<div className="h-fit rounded-lg bg-[#f50] px-3 py-1 text-sm font-semibold text-white">
												Save {plan.discountPercent}%
											</div>
										)}
									</div>

									<div className="pt-1" />

									<div className="font-medium text-black-7/80 dark:text-white/80">{plan.description}</div>

									<div className="pt-4" />

									<div className="text-3xl font-bold text-gray-700 dark:text-gray-200">
										{selectedPlanType === 'monthly' ? `$${plan.pricing.monthly}` : `$${plan.pricing.yearly}`}/mo*
									</div>

									<div className="font-medium text-black-7/80 dark:text-white/80">{plan.minutes}</div>

									<div className="pt-5" />

									<Button
										className="font-semibold"
										block
										type="primary"
										size="large"
										icon={user?.current_subscription === plan.name ? <IoCheckmarkSharp /> : null}
										onClick={() => {
											if (user?.current_subscription === plan.name) {
												return toast.success('You are already subscribed to this plan.');
											}
											setSelectedPlan(plan.name);
											handleSubscription(plan.name);
										}}
										loading={loading.subscription && selectedPlan === plan.name}
									>
										{user?.current_subscription === plan.name ? 'Subscribed' : 'Subscribe'}
									</Button>

									<div className="pt-5" />

									<div className="text-base font-semibold text-black-7/90 dark:text-white/90">{plan.displayFeatureTitle}</div>

									<div className="pt-2" />

									<div className="flex flex-col gap-1">
										{plan.displayFeatures.map((feature, index) => (
											<div
												key={index}
												className="flex items-center gap-1"
											>
												<div className="dark:text-white">
													<IoCheckmark size={18} />
												</div>
												<div className="text-base font-medium text-black-7/80 dark:text-white/80">{feature}</div>
											</div>
										))}
									</div>

									{user?.current_subscription === plan.name && (
										<>
											<div className="pt-2" />

											<div className="font-semibold text-black-7 dark:text-white">
												Cancel subscription?{' '}
												<span
													className={cn('text-blue-600 dark:text-blue-400', {
														'pointer-events-none': cancelSubscriptionLoading,
													})}
													onClick={() => {
														// handle cancel subscription
														cancelSubscription();
													}}
													role="button"
												>
													{cancelSubscriptionLoading ? 'Processing...' : 'Click here'}
												</span>
											</div>
										</>
									)}
								</div>
							))}
						</div>

						<div className="pt-10" />

						<div className="flex justify-center">
							<div
								className="flex items-center gap-2"
								onClick={() => {
									setActiveView('comparison');
								}}
								role="button"
							>
								<div className="text-base font-semibold text-blue-600 dark:text-blue-400">See Full Comparison</div>

								<HiArrowLongRight
									className="mt-1 text-blue-600 dark:text-blue-400"
									size={20}
								/>
							</div>
						</div>
					</motion.div>
				)}

				{activeView === 'comparison' && (
					<motion.div
						initial={{ y: 10, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -10, opacity: 0 }}
						transition={{ duration: 0.2 }}
					>
						<div className="flex flex-wrap items-center justify-between gap-2">
							<div>
								<div className="text-xl font-bold text-black-7 dark:text-white">Plans & Pricing Comparison</div>
								<div className="text-base font-medium text-gray-600 dark:text-gray-300">
									Compare all plans and choose the best one for your business.
								</div>
							</div>

							<div
								className="flex items-center gap-2"
								onClick={() => {
									setActiveView('pricing');
								}}
								role="button"
							>
								<HiArrowLongLeft
									className="text-blue-600 dark:text-blue-400"
									size={20}
								/>

								<div className="text-base font-semibold text-blue-600 dark:text-blue-400">Back to Pricing</div>
							</div>
						</div>

						<div className="pt-7" />

						<div className="grid grid-cols-4 gap-4">
							<div className="invisible items-center gap-3 md:visible md:flex">
								<div className="font-semibold text-gray-600 dark:text-gray-200">Monthly</div>
								<Switch
									checked={selectedPlanType === 'yearly'}
									onChange={(c) => {
										setSelectedPlanType(c ? 'yearly' : 'monthly');
									}}
								/>
								<div>
									<div className="font-semibold text-gray-600 dark:text-gray-200">Yearly</div>
									<div className="text-sm text-gray-500 dark:text-gray-300">20% OFF</div>
								</div>
							</div>

							{subscriptionPlans.map((plan, index) => (
								<div
									key={index}
									className="flex flex-col items-center justify-center rounded-t-lg bg-gray-50 py-5 dark:bg-[#1f1d2b]"
								>
									<div className="text-lg font-bold capitalize text-black-7 dark:text-white">{plan.name}</div>

									<div className="text-base font-bold text-gray-500 dark:text-gray-200">
										{selectedPlanType === 'monthly' ? `$${plan.pricing.monthly}` : `$${plan.pricing.yearly}`}/mo*
									</div>

									<div className="pt-2" />

									<Button
										className={'hidden font-semibold md:inline-flex'}
										type="primary"
										size="large"
										icon={user?.current_subscription === plan.name ? <IoCheckmarkSharp /> : null}
										onClick={() => {
											if (user?.current_subscription === plan.name) {
												return toast.success('You are already subscribed to this plan.');
											}
											setSelectedPlan(plan.name);
											handleSubscription(plan.name);
										}}
										loading={loading.subscription && selectedPlan === plan.name}
									>
										{user?.current_subscription === plan.name ? 'Subscribed' : 'Subscribe'}
									</Button>
								</div>
							))}
						</div>

						<div className="border-b shadow dark:border-b-[#2d2b38]" />

						<div className="pt-4" />

						{subscriptionPlans[0].comparison.map((category, categoryIndex) => (
							<div key={categoryIndex}>
								<div className="text-base font-semibold text-gray-500 dark:text-gray-100">{category.title}</div>
								<div className="pt-2" />
								<div className="flex flex-col">
									{category.features.map((feature, featureIndex) => (
										<div
											className="grid grid-cols-4 gap-4 py-2 hover:cursor-pointer hover:bg-gray-100 dark:hover:bg-[#1f1d2b]"
											key={featureIndex}
										>
											<div className="text-base font-medium text-gray-700 dark:text-gray-100">{feature.label}</div>
											{subscriptionPlans.map((plan) => (
												<div
													className="flex items-center justify-center dark:text-white"
													key={plan.name}
												>
													{typeof plan.comparison[categoryIndex].features[featureIndex].value === 'boolean' ? (
														plan.comparison[categoryIndex].features[featureIndex].value ? (
															<IoCheckmark
																size={20}
																color="green"
															/>
														) : (
															<BsDashLg
																size={18}
																className="dark:text-white"
															/>
														)
													) : (
														plan.comparison[categoryIndex].features[featureIndex].value
													)}
												</div>
											))}
										</div>
									))}
								</div>

								{category.helperText && <div className="pt-1 text-base text-gray-500 dark:text-gray-400">{category.helperText}</div>}

								{categoryIndex !== subscriptionPlans[0].comparison.length - 1 && <div className="pt-6" />}
							</div>
						))}
					</motion.div>
				)}

				{activeView === 'payment_methods' && (
					<motion.div
						initial={{ y: 10, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -10, opacity: 0 }}
						transition={{ duration: 0.2 }}
					>
						<div className="flex flex-wrap items-center justify-between gap-2">
							<div>
								<div className="text-xl font-bold text-black-7 dark:text-white">Finish Payment</div>
								<div className="text-base font-medium text-gray-600 dark:text-gray-300">
									Use a saved payment method to finish payment.
								</div>
							</div>

							<div
								className="flex items-center gap-2"
								onClick={() => {
									setActiveView('pricing');
								}}
								role="button"
							>
								<HiArrowLongLeft
									className="text-blue-600 dark:text-blue-400"
									size={20}
								/>

								<div className="text-base font-semibold text-blue-600 dark:text-blue-400">Back to Pricing</div>
							</div>
						</div>

						<div className="pt-8" />

						<div className="mx-auto grid max-w-lg grid-cols-1 gap-4">
							{paymentMethods.map((card, index) => (
								<AntdCard
									key={index}
									styles={{ body: { padding: 12 } }}
									onClick={() => {
										setSelectedCard(card);
									}}
									role="button"
								>
									<div className="flex items-center justify-between gap-2">
										<div className="flex items-center gap-4">
											<img
												src={cardTypeToImage(card.card.brand).logo}
												alt={cardTypeToImage(card.card.brand).alt}
												className="w-14"
											/>

											<div>
												<div className="text-base font-semibold leading-5">**** **** **** {card.card.last4}</div>
												<div className="text-sm font-medium">
													Expiry {card.card.exp_month}/{card.card.exp_year}
												</div>
											</div>
										</div>

										{selectedCard?.id === card.id && <MdOutlineRadioButtonChecked size={20} />}
									</div>
								</AntdCard>
							))}

							<Button
								block
								type="primary"
								className="font-semibold"
								onClick={handlePaymentWithSavedMethod}
								loading={loading.payment}
							>
								Pay Now
							</Button>
						</div>

						<div className="pt-4" />

						<div className="flex justify-center">
							<div
								className={cn('flex items-center gap-2', {
									'pointer-events-none': loading.subscription,
								})}
								onClick={handleNewPaymentMethod}
								role="button"
							>
								<MdAddCard
									className="text-blue-600 dark:text-blue-400"
									size={20}
								/>

								<div className={'text-base font-semibold text-blue-600 dark:text-blue-400'}>
									{loading.subscription ? 'Processing...' : 'New Payment Method'}
								</div>
							</div>
						</div>
					</motion.div>
				)}

				{activeView === 'payment' && (
					<motion.div
						initial={{ y: 10, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -10, opacity: 0 }}
						transition={{ duration: 0.2 }}
					>
						<div className="flex flex-wrap items-center justify-between gap-2">
							<div>
								<div className="text-xl font-bold text-black-7 dark:text-white">Finish Payment</div>
								<div className="text-base font-medium text-gray-600 dark:text-gray-300">
									Payment will be processed securely using Stripe.
								</div>
							</div>

							<div
								className="flex items-center gap-2"
								onClick={() => {
									setActiveView('pricing');
								}}
								role="button"
							>
								<HiArrowLongLeft
									className="text-blue-600 dark:text-blue-400"
									size={20}
								/>

								<div className="text-base font-semibold text-blue-600 dark:text-blue-400">Back to Pricing</div>
							</div>
						</div>

						<div className="pt-10" />

						<form
							onSubmit={handlePayment}
							className="mx-auto max-w-lg rounded-lg border p-5 dark:border-[#2d2b38]"
						>
							<CardElement />

							<div className="pt-5" />

							<Button
								htmlType="submit"
								block
								type="primary"
								className="font-semibold"
								loading={loading.payment}
							>
								Finish Payment
							</Button>
						</form>
					</motion.div>
				)}
			</div>

			<div className="pb-10" />
		</AppLayout>
	);
};
