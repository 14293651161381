import Icon from '@ant-design/icons/lib/components/Icon';
import { useMutation } from '@tanstack/react-query';
import { Alert, Button, Modal } from 'antd';
import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { MdAddIcCall } from 'react-icons/md';
import { RiCloseCircleFill } from 'react-icons/ri';
import axios from '../config/axios';
import { cn } from '../config/cn';
import { PhoneNumber } from '../types/phone-numbers.types';

type Props = {
	open: boolean;
	close: () => void;
	closeDrawer: () => void;
	phone_number: PhoneNumber;
	refetch: () => void;
};

const BuyPhoneModal: FC<Props> = ({ open, close, phone_number, closeDrawer, refetch }) => {
	const [alertMessage, setAlertMessage] = useState<string | null>(null);

	// buy phone number mutation
	const { mutate: buyPhoneNumber, isPending: isBuyingPhoneNumber } = useMutation({
		mutationFn: async (phone_number: string) => {
			await axios.post(
				'/phone-numbers/buy-phone-number',
				{
					phone_number,
				},
				{ withCredentials: true },
			);
		},
		onSuccess: () => {
			setAlertMessage('Phone number purchased successfully. Redirecting...');
			refetch();
			// close modal and drawer after 1.5 seconds
			setTimeout(() => {
				close();
				closeDrawer();
			}, 1500);
		},
		onError: (error: AxiosError<{ msg: string }>) => {
			setAlertMessage(error.response?.data.msg as string);
		},
	});

	// close error message after 3 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setAlertMessage(null);
		}, 3000);

		return () => clearTimeout(timer);
	}, [alertMessage]);

	return (
		<Modal
			title="Buy Phone Number"
			open={open}
			onCancel={close}
			destroyOnClose
			centered
			footer={null}
		>
			<h2 className="pb-1.5 font-semibold text-gray-700">Your New Phone Number</h2>

			<div className="rounded-md bg-gray-200 px-3">
				<div className="flex items-center justify-between gap-2 py-2">
					<div className="flex items-center gap-1.5">
						<div>
							<MdAddIcCall size={20} />
						</div>
						<div className="font-semibold">{phone_number.friendly_name}</div>
					</div>

					<div className="flex items-center gap-1 font-semibold">
						<div className="text-base">${phone_number.monthly_fee}</div> / month
					</div>
				</div>
			</div>

			<div
				className={cn('pt-3', {
					'pb-3': alertMessage,
				})}
			>
				<h3 className="pb-1 text-base font-semibold text-gray-700">Includes: </h3>

				<div className="space-y-0.5">
					<div>
						{phone_number.capabilities.voice ? (
							<div className="flex items-center gap-1">
								<div>
									<IoIosCheckmarkCircle
										size={20}
										className="text-green-500"
									/>
								</div>
								<div>Voice Capability</div>
							</div>
						) : (
							<div className="flex items-center gap-1">
								<div>
									<RiCloseCircleFill
										size={20}
										className="text-red-500"
									/>
								</div>
								<div>Voice Capability</div>
							</div>
						)}
					</div>

					<div>
						{phone_number.capabilities.SMS ? (
							<div className="flex items-center gap-1">
								<div>
									<IoIosCheckmarkCircle
										size={20}
										className="text-green-500"
									/>
								</div>
								<div>SMS Capability</div>
							</div>
						) : (
							<div className="flex items-center gap-1">
								<div>
									<RiCloseCircleFill
										size={20}
										className="text-red-500"
									/>
								</div>
								<div>SMS Capability</div>
							</div>
						)}
					</div>

					<div className="flex items-center gap-1">
						<div>
							<IoIosCheckmarkCircle
								size={20}
								className="text-green-500"
							/>
						</div>
						<div>{phone_number.iso_country} Based Phone Number</div>
					</div>

					<div className="flex items-center gap-1">
						<div>
							<IoIosCheckmarkCircle
								size={20}
								className="text-green-500"
							/>
						</div>
						<div>Lowest price in the market</div>
					</div>
				</div>
			</div>

			{alertMessage && (
				<Alert
					message={alertMessage}
					type={alertMessage.includes('successfully') ? 'success' : 'error'}
					showIcon
				/>
			)}

			<div className="flex justify-end pt-3">
				<Button
					type="primary"
					icon={<Icon component={FaCheck} />}
					onClick={() => {
						buyPhoneNumber(phone_number.phone_number);
					}}
					loading={isBuyingPhoneNumber}
					disabled={isBuyingPhoneNumber}
				>
					Confirm Purchase
				</Button>
			</div>
		</Modal>
	);
};

export default BuyPhoneModal;
