import { Modal } from 'antd';
import { AxiosError } from 'axios';

export const handleAPIError = (error: Error) => {
	if (error instanceof AxiosError && error.response) {
		Modal.error({
			title: 'Error',
			content: error.response.data.msg,
		});
	} else {
		Modal.error({
			title: 'Error',
			content: error.message,
		});
	}
};

// convert seconds to proper format including hours
export const formatSeconds = (seconds: number): string => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	let result = '';

	if (hours > 0) {
		result += `${hours} hr `;
	}
	if (minutes > 0 || hours > 0) {
		// Include minutes if there are hours, even if minutes are 0
		result += `${minutes} min `;
	}
	result += `${remainingSeconds} sec`;

	return result.trim();
};
