import { Modal } from 'antd';
import { FC } from 'react';
import { useRuthConnectStore } from '../../store/ruth-connect';
import { App } from './ruth-connect-data';

type CreateIntegrationModalProps = {
	item: App;
};

const CreateIntegrationModal: FC<CreateIntegrationModalProps> = (props) => {
	const { openModalId, setOpenModalId } = useRuthConnectStore();
	const isOpen = openModalId === props.item.uuid;

	return (
		<Modal
			open={isOpen}
			onCancel={() => {
				setOpenModalId(null);
			}}
			footer={null}
			destroyOnClose
			title="Connect"
			centered
		>
			{props.item.connect_UI}
		</Modal>
	);
};

export default CreateIntegrationModal;
